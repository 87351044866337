import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const BagIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.026 4.235a2.405 2.405 0 0 1 2.37-1.985c1.188 0 2.173.858 2.37 1.985h-4.74Zm-1.01 0a3.404 3.404 0 0 1 3.38-2.985 3.404 3.404 0 0 1 3.38 2.985h2.016v10.77H3V4.235h2.016ZM4 5.235h8.792v8.77H4v-8.77Z"
      clipRule="evenodd"
    />
  </Svg>
);

export default BagIcon;
