import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SvgComponent = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm5.476 6h-2.49c-.111-2.282-.99-3.896-1.71-4.85a5.512 5.512 0 0 1 4.2 4.85Zm-7.462 1h3.972C9.84 11.1 8.596 12.694 8 13.313c-.597-.62-1.841-2.213-1.986-4.813Zm0-1C6.16 4.9 7.404 3.306 8 2.687c.597.621 1.841 2.215 1.986 4.813H6.014Zm.71-4.85c-.72.954-1.599 2.568-1.71 4.85h-2.49a5.512 5.512 0 0 1 4.2-4.85Zm-4.2 5.85h2.49c.113 2.282.99 3.896 1.71 4.85a5.512 5.512 0 0 1-4.2-4.85Zm6.75 4.85c.72-.954 1.597-2.568 1.71-4.85h2.49a5.512 5.512 0 0 1-4.198 4.85h-.003Z"
    />
  </Svg>
);

export default SvgComponent;
