import { Environments } from '.';
import { SiteType } from '../graphql/codegen';

const CONTAINER_ID_GSTAR = 'GTM-PPGBGR8';
const CONTAINER_ID_AAF = 'GTM-P7LKQM5X';

const gtmStagingConfigGstar = {
  containerId: CONTAINER_ID_GSTAR,
  authId: 'bh-lXppDHi_K7lhhZaRzLw',
  prevId: 'env-5',
};

const gtmAcceptanceConfigGstar = {
  containerId: CONTAINER_ID_GSTAR,
  authId: 'M5qKMvr22Rnw_e7oKkn7og',
  prevId: 'env-7',
};

const gtmStagingConfigAaf = {
  containerId: CONTAINER_ID_AAF,
  authId: 'M-4xf7TOaURoNyikmD2qgg',
  prevId: 'env-3',
};

const gtmAcceptanceConfigAaf = {
  containerId: CONTAINER_ID_AAF,
  authId: 'mvWD7u9gk4iZswQZ784RvA',
  prevId: 'env-4',
};

const gtmProductionConfigGstar = { containerId: CONTAINER_ID_GSTAR };
const gtmProductionConfigAaf = { containerId: CONTAINER_ID_AAF };

export const gtmIds: {
  [k: string]: { [k: string]: { containerId: string; authId?: string; prevId?: string } };
} = {
  [SiteType.Aaf]: {
    [Environments.DEV]: gtmStagingConfigAaf,
    [Environments.TST]: gtmStagingConfigAaf,
    [Environments.ACC]: gtmAcceptanceConfigAaf,
    [Environments.PRD]: gtmProductionConfigAaf,
  },
  [SiteType.Gstar]: {
    [Environments.DEV]: gtmStagingConfigGstar,
    [Environments.TST]: gtmStagingConfigGstar,
    [Environments.ACC]: gtmAcceptanceConfigGstar,
    [Environments.PRD]: gtmProductionConfigGstar,
  },
};

export const getGtmConfig = (siteType: SiteType, environment: Environments) => {
  if (siteType === SiteType.Aaf) {
    return gtmIds[SiteType.Aaf][environment];
  }

  return gtmIds[SiteType.Gstar][environment];
};
