import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialTwitterInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1024 512C1024 794.77 794.77 1024 512 1024C229.23 1024 0 794.77 0 512C0 229.23 229.23 0 512 0C794.77 0 1024 229.23 1024 512ZM574.758 473.458L737.518 729.246C739.515 732.39 740.632 736.014 740.751 739.737C740.869 743.461 739.986 747.148 738.193 750.413C736.399 753.678 733.762 756.402 730.556 758.3C727.35 760.197 723.693 761.199 719.968 761.2H595.168C591.679 761.213 588.243 760.348 585.176 758.685C582.11 757.022 579.51 754.615 577.618 751.684L472.344 586.246L319.36 754.518C317.533 756.577 315.316 758.253 312.837 759.449C310.358 760.645 307.666 761.337 304.917 761.484C302.169 761.632 299.418 761.233 296.825 760.31C294.232 759.387 291.848 757.959 289.811 756.108C287.774 754.256 286.125 752.019 284.959 749.526C283.793 747.033 283.133 744.333 283.018 741.583C282.903 738.833 283.335 736.087 284.289 733.505C285.243 730.923 286.7 728.557 288.576 726.542L449.178 549.742L286.418 293.98C284.413 290.835 283.291 287.209 283.169 283.482C283.047 279.754 283.929 276.063 285.723 272.793C287.517 269.524 290.157 266.797 293.367 264.898C296.577 262.999 300.238 261.998 303.968 262H428.768C432.268 262.001 435.712 262.886 438.78 264.572C441.848 266.258 444.44 268.691 446.318 271.646L551.592 437.084L704.576 268.812C708.305 264.806 713.463 262.431 718.932 262.203C724.4 261.974 729.738 263.91 733.789 267.591C737.84 271.273 740.276 276.402 740.57 281.867C740.864 287.333 738.992 292.694 735.36 296.788L574.758 473.458ZM341.85 303.6L606.582 719.6H682.086L417.25 303.6H341.85Z"
        />
      </g>
    </g>
  </Svg>
);

export default SocialTwitterInvertedIcon;
