import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SearchIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="m14.354 13.646-3.13-3.129a5.507 5.507 0 1 0-.707.707l3.13 3.13a.5.5 0 0 0 .707-.708ZM2.5 7a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Z"
    />
  </Svg>
);

export default SearchIcon;
