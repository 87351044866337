import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { Newsletter } from '../newsletter/Newsletter';
import { GenericLinkPanel } from './genericLinkList/GenericLinkPanel';
import { MetaInfo } from './metainfo/MetaInfo';
import { SocialLinks } from './socialLinks/SocialLinks';
import type { GlobalFooterNavigation } from '../../../amplienceTypes/schemas/imported/global-footer-navigation-schema';
import { useLazyLoading } from '../../../utilities/hooks';
import { media } from '../core/styles';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

const S = {
  Container: styled.div`
    ${({ theme }) => css`
      min-height: 1px;
      background-color: ${theme.colors.background.component.footer};

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding: ${theme.size[12]} ${theme.size[4]};

          @media ${media(theme).greaterThan('sm')} {
            padding: ${theme.size[12]} ${theme.size[6]};
          }

          @media ${media(theme).greaterThan('desktop-lg')} {
            padding: ${theme.size[12]} ${theme.size[12]};
          }
        `,
      })}
    `}
  `,

  FooterNavigation: styled.div`
    ${({ theme }) => css`
      margin: 0 auto;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          max-width: 100%;
        `,
        [SiteType.Aaf]: css`
          max-width: 1376px;
        `,
      })}
    `}
  `,

  Section: styled.div`
    ${({ theme }) => css`
      display: flex;
      justify-content: space-between;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          flex-direction: column-reverse;
          gap: ${theme.size[12]};
          max-width: 1656px;
          margin: 0 auto;

          @media ${media(theme).greaterThan('sm')} {
            gap: ${theme.size[6]};
          }

          @media ${media(theme).greaterThan('xl')} {
            flex-direction: row;
          }
        `,
        [SiteType.Aaf]: css`
          flex-direction: column-reverse;
          align-self: stretch;
          padding: ${theme.size[8]} ${theme.size[4]};

          @media ${media(theme).greaterThan('sm')} {
            flex-direction: row;
            padding: ${theme.size[8]};
          }
        `,
      })}
    `}
  `,
};

interface FooterNavigationInterface extends GlobalFooterNavigation {
  amplienceShowAppStoreLinks?: boolean;
}

export const FooterNavigation = ({
  appStoreLinks,
  newsletter,
  amplienceShowAppStoreLinks,
}: FooterNavigationInterface): ReactElement => {
  const {
    configuration: { activeApp },
  } = useStaticContext();
  const showAppStoreLinks = activeApp && amplienceShowAppStoreLinks;
  const { ref, isIntersecting } = useLazyLoading();
  const { isGStar } = useAppContext();

  const renderComponent = () => (
    <>
      <S.FooterNavigation>
        <S.Section>
          <GenericLinkPanel />
          {newsletter?.content && <Newsletter {...newsletter.content} showClubGIcon />}
        </S.Section>
        {!isGStar && <SocialLinks />}
        {!isGStar && <MetaInfo />}
      </S.FooterNavigation>
    </>
  );

  return (
    <>
      <S.Container ref={ref}>{isIntersecting && renderComponent()}</S.Container>{' '}
      {isGStar && (
        <MetaInfo
          appStoreLinks={showAppStoreLinks && appStoreLinks ? appStoreLinks.content : undefined}
        />
      )}
    </>
  );
};
