import type { ReactElement } from 'react';
import Image from 'next/legacy/image';
import styled, { css } from 'styled-components';
import { Typography } from '../../core/typography/Typography';
import { media } from '../../core/styles';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { CHINA_CODE } from '~/utilities/constants';
import type { PaymentMethods } from '~/utilities/graphql/codegen';
import { useMetaInfoPaymentMethodsQuery, SiteType } from '~/utilities/graphql/codegen';
import { paymentIconStyles } from '~/utilities/constants/paymentIcons';
import { LegalCopyright } from '~/utilities/constants/siteType.config';
import { AppStoreLinks } from '../appStoreLinks/AppStoreLinks';
import { getSiteStyles } from '~/utilities/helpers';
import { LOGO } from '~/utilities/constants/base64Images';
import { SocialLinks } from '../socialLinks/SocialLinks';
import type { GlobalAppStoreLinks } from '../../../../amplienceTypes/schemas/imported/global-footer-navigation-schema';

const S = {
  PaymentMethod: styled.div`
    ${({ theme }) => css`
      position: relative;
      width: auto;
      padding: 0 0 10px;
      margin: 0 0 45px;
      text-align: center;
      border-top: 1px solid ${theme.colors.content.secondary};
      border-bottom: 1px solid ${theme.colors.content.secondary};
      clear: both;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          order: 1;
          display: flex;
          justify-content: center;
          flex-basis: 100%;
          align-items: center;
          margin: 0;
          padding: 0 ${theme.size[4]} ${theme.size[6]};
          flex-grow: 1;
          border: unset;

          @media ${media(theme).greaterThan('sm')} {
            order: 1;
            padding: 0 ${theme.size[6]} ${theme.size[6]};
          }

          @media ${media(theme).greaterThan('xl')} {
            order: 2;
            padding: 0;
          }
        `,
      })}
    `}
  `,

  PaymentMethodLink: styled.a`
    ${({ theme }) => css`
      display: inline-block;
      color: ${theme.colors.content.tertiary};

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;

          @media ${media(theme).greaterThan('sm')} {
            gap: 24px;
          }
        `,
        [SiteType.Aaf]: css`
          color: ${theme.colors.content.secondary};
        `,
      })}
    `}
  `,

  PaymentMethodIcon: styled.span<{ $width: number; $backgroundPosition: string }>`
    ${({ theme, $width, $backgroundPosition }) => css`
      display: inline-block;
      height: 22px;
      background-image: url('/_fes/${process.env.BUILD_TIME}/img/sprites/payment.png');
      background-repeat: no-repeat;
      font-size: 0;
      width: ${$width}px;
      background-position: ${$backgroundPosition};
      margin: 15px 8px 5px;

      @media ${media(theme).greaterThan('sm')} {
        margin: 20px 20px 0;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          margin: 0;
          transform: scale(0.7272);

          @media ${media(theme).greaterThan('sm')} {
            margin: 0;
            transform: scale(1);
          }
        `,
      })}
    `}
  `,

  CopyRight: styled(Typography)`
    ${({ theme }) => css`
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          order: 2;
          display: flex;
          height: 42px;
          line-height: initial;
          align-items: flex-end;
          border-top: 1px solid ${theme.colors.border.subtle};
          padding: ${theme.size[6]} 0 ${theme.size[12]} ${theme.size[4]};
          text-align: start;
          background-image: url(${LOGO.gstarDark});
          background-repeat: no-repeat;
          background-size: 108px 18px;
          background-origin: content-box;
          font-size: ${theme.font.size[1]};
          color: ${theme.colors.slate[600]};
          flex-grow: 1;

          @media ${media(theme).greaterThan('sm')} {
            display: none;
          }
        `,
        [SiteType.Aaf]: css`
          color: ${theme.colors.content.secondary};
          font-size: ${theme.font.size[2]};
          font-style: normal;
          font-weight: 400;
          line-height: 136%;
          letter-spacing: 0.12px;
          padding: ${theme.size[4]} ${theme.size[2]} ${theme.size[8]} ${theme.size[2]};

          @media ${media(theme).greaterThan('sm')} {
            padding-top: 0;
          }
        `,
      })}
    `}
  `,

  CnGovReq: styled(Typography)`
    margin-bottom: 20px;
    line-height: 34px;
    font-size: 11px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,

  CnLegalReq: styled(Typography)`
    height: 34px;
    min-width: 34px;
    padding-right: 39px;
    font-size: 11px;
    line-height: 34px;
    background: url('/_fes/${process.env.BUILD_TIME}/img/cn_legal_req.gif') no-repeat right;
    text-align: center;
  `,

  CnSzfwReq: styled.a`
    ${({ theme }) => css`
      text-decoration: none;
      display: flex;

      @media ${media(theme).lessThan('sm')} {
        flex-basis: 100%;
        justify-content: center;
      }
    `}
  `,

  Link: styled.a`
    ${({ theme }) => css`
      text-decoration: none;
      color: ${theme.colors.content.secondary};
    `}
  `,

  BottomWrapper: styled.div`
    ${({ theme }) => css`
      display: flex;
      flex-wrap: wrap;
      padding: ${theme.size[6]} 0 0;
      max-width: 1656px;
      margin: 0 auto;
      border-top: 1px solid ${theme.colors.border.subtle};

      @media ${media(theme).greaterThan('sm')} {
        padding-bottom: ${theme.size[12]};
      }

      @media ${media(theme).greaterThan('xl')} {
        padding: ${theme.size[6]} ${theme.size[6]} ${theme.size[12]};
        flex-wrap: nowrap;
        gap: ${theme.size[3]};
      }
    `}
  `,
};

interface MetaInfoProps {
  appStoreLinks?: GlobalAppStoreLinks;
}

const MetaInfoComponent = ({ appStoreLinks }: MetaInfoProps): ReactElement => {
  const { country, locale, siteType, isGStar } = useAppContext();

  const { loading, error, data } = useMetaInfoPaymentMethodsQuery({
    variables: {
      siteType,
      locale,
    },
    ssr: false,
  });

  if (loading || error) {
    return <></>;
  }

  const paymentMethods = data?.paymentMethods as PaymentMethods[];

  const paymentUrl =
    paymentMethods.find(paymentMethod => paymentMethod.url)?.url ?? '/customer-service/payments';

  return (
    <>
      {isGStar ? (
        <S.BottomWrapper>
          <S.CopyRight component="span" variant="body" alignment="left" testId="footer-copyright">
            {LegalCopyright[siteType]} {new Date().getFullYear()}
          </S.CopyRight>
          <SocialLinks />

          {isGStar && country !== CHINA_CODE && paymentMethods?.length && (
            <S.PaymentMethod data-testid="footer-payment-methods">
              <S.PaymentMethodLink href={paymentUrl} aria-label="Footer payment methods">
                {paymentMethods.map((paymentMethod, i) => {
                  const iconStyle = paymentIconStyles.find(
                    iStyle => iStyle.method === paymentMethod.code
                  );

                  return iconStyle ? (
                    <S.PaymentMethodIcon
                      $width={iconStyle.width}
                      $backgroundPosition={iconStyle.position}
                      key={i}
                    />
                  ) : null;
                })}
              </S.PaymentMethodLink>
            </S.PaymentMethod>
          )}

          {appStoreLinks && isGStar && <AppStoreLinks {...appStoreLinks} />}
        </S.BottomWrapper>
      ) : (
        <S.CopyRight component="p" variant="body" alignment="center" testId="footer-copyright">
          {LegalCopyright[siteType]} {new Date().getFullYear()}
        </S.CopyRight>
      )}

      {country === CHINA_CODE && (
        <S.CnGovReq variant="body">
          <S.Link href="https://beian.miit.gov.cn/" target="_blank">
            沪ICP备14007988号-1
          </S.Link>
          <S.CnLegalReq variant="body" component="span">
            &nbsp;上海工商
          </S.CnLegalReq>
          &nbsp;
          <S.CnSzfwReq href="https://search.szfw.org/cert/l/CX20150511007725010050" target="_blank">
            <Image
              src="/img/ceca-icon.jpg"
              decoding="async"
              loading="lazy"
              alt="szfw requirement"
              width="95"
              height="34"
              layout="fixed"
            />
          </S.CnSzfwReq>
        </S.CnGovReq>
      )}
    </>
  );
};

export const MetaInfo = MetaInfoComponent;
