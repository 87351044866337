import { useRef, useEffect } from 'react';
import type { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { media } from '../../core/styles';
import type { ButtonProps } from '../../core/button/Button';
import SearchIcon from '../../core/icons/SearchIcon';
import SearchIconAaf from '../../core/icons/SearchIconAaf';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { HeaderButton } from '../HeaderStyle';

const S = {
  Toggle: styled.button`
    ${() => css`
      ${HeaderButton}
    `}
  `,
  Icon: styled.span`
    display: flex;
    width: 16px;
    height: 16px;

    @media ${({ theme }) => media(theme).greaterThan('xl')} {
      width: 16px;
      height: 16px;
    }
  `,
};

export const ToggleSearch = (props: ButtonProps): ReactElement => {
  const toggleRef = useRef<HTMLButtonElement>(null);
  const { isGStar } = useAppContext();
  const theme = useTheme();

  const setFocusOnSearchInput = () => {
    const inputElement: HTMLElement | null = document.querySelector('input#searchBox_input');

    if (inputElement) {
      inputElement.focus();
    }
  };

  useEffect(() => {
    const refValue = toggleRef.current;

    if (refValue) {
      refValue.addEventListener('click', setFocusOnSearchInput);
    }

    return () => {
      if (refValue) {
        refValue.removeEventListener('click', setFocusOnSearchInput);
      }
    };
  }, [toggleRef]);

  return (
    <S.Toggle {...props} ref={toggleRef} data-testid="search-toggle" aria-label="search-toggle">
      <S.Icon>
        {isGStar ? (
          <SearchIcon
            color={theme.colors.content.component.header.color}
            hoverColor={theme.colors.content.component.header.hover}
          />
        ) : (
          <SearchIconAaf />
        )}
      </S.Icon>
    </S.Toggle>
  );
};
