import { memo, useState } from 'react';
import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';
import isEqual from 'react-fast-compare';
import dynamic from 'next/dynamic';
import type { GlobalTopNavigation as GlobalTopNavigationProps } from '../../../amplienceTypes/schemas/imported/global-top-navigation-schema';
import { NavLinks } from './navLinks/NavLinks';
import { HamburgerButton } from './sideBar/HamburgerButton';
import { Logo } from './logo/Logo';
import { media } from '../core/styles';
import { ToggleSearch } from './searchBox/ToggleSearch';
import { ToggleSearchV2 } from './searchBoxV2/ToggleSearchV2';
import { LocaleSelectorButton } from './localeSelector/LocaleSelectorButton';
import { LoginButton } from './login/LoginButton';
import { WishListButton } from './miniWishlist/WishListButton';
import { MiniWishlist } from './miniWishlist/MiniWishlist';
import { ShoppingCartButton } from './miniCart/MiniCartButton';
import { MiniCart } from './miniCart/MiniCart';
import { SideNavHeader } from './sideBar/SideNavHeader';
import { MessageBoxNotification } from './messageBox/MessageBoxNotification';
import type { HeaderOpenModalProps, OpenedModal } from '../core/layouts/MasterLayout';
import { useGalleryContext } from '~/utilities/context/dynamic/GalleryContext';
import { useNavActionContext } from '~/utilities/context/dynamic/NavActionContext';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import type { GlobalMessageBoxNotification } from '~/amplienceTypes/schemas/imported/global-message-box-notification-schema';
import { useSearchContext } from '~/utilities/context/dynamic/SearchContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

const SearchBox = dynamic(async () => (await import('./searchBox/SearchBox')).SearchBox, {
  ssr: false,
});
const SearchBoxV2 = dynamic(async () => (await import('./searchBoxV2/SearchBoxV2')).SearchBoxV2, {
  ssr: false,
});

interface TopNavigationProps {
  showCookieWall?: boolean;
  loginLabel?: string;
  messageBoxNotification?: GlobalMessageBoxNotification;
}

const S = {
  Header: styled.div<{
    $galleryZoomIn: boolean;
    $showCookieWall: boolean;
    $openedModal: OpenedModal;
  }>`
    ${({ theme, $galleryZoomIn, $showCookieWall, $openedModal }) => css`
      display: ${$galleryZoomIn ? 'none' : 'block'};
      position: fixed;
      font-size: 14px;
      height: var(--top-nav-height);
      left: 0;
      right: 0;
      top: var(--smart-banner-height);
      width: 100%;
      z-index: 504;
      transform: translateY(0) translateZ(0);
      transition: transform 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      pointer-events: ${$showCookieWall ? 'none' : 'auto'};

      @media ${media(theme).lessThan('sm')} {
        display: ${$openedModal === 'message-box-notification' || $galleryZoomIn
          ? 'none'
          : 'block'};
      }

      @media ${media(theme).greaterThan('sm')} {
        z-index: 503;
        top: 0;
      }

      @media ${media(theme).greaterThan('lg')} {
        display: block;
      }

      @media (prefers-reduced-motion) {
        transition: none;
      }

      .is-highstreet & {
        display: none;
      }
    `}
  `,

  MainContent: styled.div`
    ${({ theme }) => css`
      background-color: ${theme.colors.background.component.header};
      height: var(--top-nav-height);

      @media ${media(theme).lessThan('sm')} {
        transform: translate3d(var(--side-nav-width), 0, 0);
        transition-timing-function: ease-in-out;
        transition-duration: 0.25s;
        transition-property: left, width, transform;
        will-change: left, width;
        z-index: 1;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          box-sizing: border-box;
          border-bottom: 1px solid ${theme.colors.border.mid};
        `,
      })}
    `}
  `,

  LeftNavWrapper: styled.div`
    ${({ theme }) => css`
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      white-space: nowrap;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).lessThan('sm')} {
            gap: ${theme.size[4]};
          }
        `,
      })}
    `}
  `,

  LeftSearchButton: styled(ToggleSearch)<{ $sideNavOpened: boolean }>`
    ${({ theme, $sideNavOpened }) => css`
      display: ${$sideNavOpened ? 'none' : ' inline-block'};

      .has-open-sideNavigation & {
        display: none;
      }

      @media ${media(theme).greaterThan('sm')} {
        display: none;
      }
    `}
  `,

  LeftSearchButtonV2: styled.div`
    ${({ theme }) => css`
      display: block;

      @media ${media(theme).greaterThan('sm')} {
        display: none;
      }
    `}
  `,

  RightNavWrapper: styled.div`
    ${({ theme }) => css`
      display: flex;
      gap: ${theme.size[4]};
      position: absolute;
      padding-right: ${theme.padding.sm};
      right: 0;
      top: 0;
      white-space: nowrap;

      @media ${media(theme).lessThan('sm')} {
        gap: ${theme.size[1]};
        padding-right: ${theme.padding['3xs']};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          gap: ${theme.size[6]};

          @media ${media(theme).lessThan('sm')} {
            gap: ${theme.size[6]};
            padding-right: ${theme.padding.sm};
          }
        `,
      })}
    `}
  `,

  RightSearchButton: styled(ToggleSearch)`
    ${({ theme }) => css`
      display: none;

      @media ${media(theme).greaterThan('sm')} {
        display: inline-block;
      }

      @media ${media(theme).greaterThan('xl')} {
        display: inline-block;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('xl')} {
            display: none;
          }
        `,
      })}
    `}
  `,

  RightSearchButtonV2: styled.div`
    ${({ theme }) => css`
      display: none;

      @media ${media(theme).greaterThan('sm')} {
        display: block;
      }
    `}
  `,

  DesktopOnly: styled.div`
    ${({ theme }) => css`
      display: none;

      @media ${media(theme).greaterThan('sm')} {
        display: flex;
      }
    `}
  `,
};

const TopNavigationComponent = ({
  _meta,
  showCookieWall = false,
  cart,
  wishlist,
  localeSelector,
  // To use later when we use amplience for nav links
  // navLinks,
  loginLabel,
  searchBoxPlaceholder,
  myAccountLabel,
  messageBoxNotification,
  openedModal,
  setOpenedModal,
  searchSystemTexts,
}: TopNavigationProps & GlobalTopNavigationProps & HeaderOpenModalProps): ReactElement => {
  const [searchBoxActive, setSearchBoxActive] = useState<boolean>(false);
  const [wishlistHovered, setWishlistHovered] = useState<boolean>(false);

  const { t } = useTranslation('common', { keyPrefix: 'globalTopNavigation' });
  const { galleryZoomIn } = useGalleryContext();
  const { sideNavOpened } = useNavActionContext();
  const { hasSearchV2 } = useSearchContext();
  const { isGStar } = useAppContext();

  const {
    configuration: { showMessageBoxNotifications, enableWishlist },
  } = useStaticContext();

  return (
    <S.Header
      $galleryZoomIn={galleryZoomIn}
      $showCookieWall={showCookieWall}
      $openedModal={openedModal}
    >
      {hasSearchV2 && <SearchBoxV2 systemTexts={searchSystemTexts?.content} />}
      {!hasSearchV2 && !isGStar && (
        <SearchBox
          searchBoxActive={searchBoxActive}
          setSearchBoxActive={setSearchBoxActive}
          placeholder={searchBoxPlaceholder}
        />
      )}
      <SideNavHeader
        localeSelector={localeSelector}
        openedModal={openedModal}
        setOpenedModal={setOpenedModal}
        _meta={_meta}
      />
      <S.MainContent suppressHydrationWarning>
        <S.LeftNavWrapper>
          <HamburgerButton />
          {!hasSearchV2 ? (
            <S.LeftSearchButton
              $sideNavOpened={sideNavOpened}
              onClick={() => setSearchBoxActive(prev => !prev)}
            />
          ) : (
            <S.LeftSearchButtonV2>
              <ToggleSearchV2 placeholder={searchBoxPlaceholder} />
            </S.LeftSearchButtonV2>
          )}
          {isGStar && <NavLinks />}
        </S.LeftNavWrapper>
        <Logo data-cs-capture="" />
        <S.RightNavWrapper>
          {isGStar && !hasSearchV2 && (
            <SearchBox
              searchBoxActive={searchBoxActive}
              setSearchBoxActive={setSearchBoxActive}
              placeholder={searchBoxPlaceholder}
            />
          )}
          {hasSearchV2 ? (
            <S.RightSearchButtonV2>
              <ToggleSearchV2 placeholder={searchBoxPlaceholder} />
            </S.RightSearchButtonV2>
          ) : (
            <S.RightSearchButton onClick={() => setSearchBoxActive(prev => !prev)} />
          )}
          {localeSelector?.content && (
            <S.DesktopOnly>
              <LocaleSelectorButton
                {...localeSelector?.content}
                openedModal={openedModal}
                setOpenedModal={setOpenedModal}
              />
            </S.DesktopOnly>
          )}
          {isGStar && messageBoxNotification && showMessageBoxNotifications && (
            <S.DesktopOnly>
              <MessageBoxNotification
                {...messageBoxNotification}
                openedModal={openedModal}
                setOpenedModal={setOpenedModal}
              />
            </S.DesktopOnly>
          )}
          <S.DesktopOnly>
            <LoginButton
              loginLabel={loginLabel}
              myAccountLabel={myAccountLabel || t('myAccountLabel')}
            />
          </S.DesktopOnly>
          {enableWishlist && (
            <>
              <WishListButton onWishlistHover={(hovered: boolean) => setWishlistHovered(hovered)} />

              {wishlist?.content && (
                <MiniWishlist
                  wishlistHovered={wishlistHovered}
                  onWishlistHover={(hovered: boolean) => setWishlistHovered(hovered)}
                  {...wishlist?.content}
                />
              )}
            </>
          )}
          <>
            <ShoppingCartButton />
            {cart?.content && <MiniCart {...cart.content} />}
          </>
        </S.RightNavWrapper>
      </S.MainContent>
    </S.Header>
  );
};

export const TopNavigation = memo(TopNavigationComponent, isEqual);
