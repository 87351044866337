import React from 'react';
import type { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';

import SocialPinterestInvertedIcon from '../../core/icons/SocialPinterestInvertedIcon';
import SocialTwitterInvertedIcon from '../../core/icons/SocialTwitterInvertedIcon';
import SocialTiktokPlainIcon from '../../core/icons/SocialTiktokPlainIcon';
import SocialEmailInvertedIcon from '../../core/icons/SocialEmailInvertedIcon';
import SocialInstagramPlainIcon from '../../core/icons/SocialInstagramPlainIcon';
import SocialFacebookPlainIcon from '../../core/icons/SocialFacebookPlainIcon';
import SocialLinkedinPlainIcon from '../../core/icons/SocialLinkedinPlainIcon';
import SocialYoutubePlainIcon from '../../core/icons/SocialYoutubePlainIcon';
import { media } from '../../core/styles';
import { LinkTarget } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';
import type { IconProps } from '../../core/icons/index';

const S = {
  Wrapper: styled.div`
    ${({ theme }) => css`
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          display: flex;
          align-items: center;
          justify-content: end;
          gap: ${theme.size[6]};
          order: 3;
          flex-grow: 1;
          border-top: 1px solid ${theme.colors.border.subtle};
          margin: 0;
          padding: ${theme.size[6]} ${theme.size[4]} ${theme.size[12]} 0;

          @media ${media(theme).greaterThan('sm')} {
            order: 2;
            padding: ${theme.size[6]} 0 0 ${theme.size[6]};
            justify-content: start;
          }

          @media ${media(theme).greaterThan('xl')} {
            order: 1;
            padding: 0;
            border: unset;
          }
        `,
        [SiteType.Aaf]: css`
          display: flex;
          justify-content: center;
          gap: ${theme.size[6]};
          margin: ${`${theme.size[8]} auto`};
        `,
      })}
    `}
  `,

  Icon: styled.div`
    ${({ theme }) => css`
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          width: ${theme.size[5]};
          height: ${theme.size[5]};
        `,
        [SiteType.Aaf]: css`
          justify-content: center;
          width: ${theme.size[4]};
          height: ${theme.size[4]};
        `,
      })}
    `}
  `,

  Link: styled.a`
    text-decoration: none;
  `,
};

export const SocialLinks = (): ReactElement => {
  const { socialMediaChannels } = useStaticContext();
  const { isGStar } = useAppContext();
  const theme = useTheme();
  const {
    component: { footer },
    primary,
  } = theme.colors.content;

  const applyIconProps = (IconComponent: React.ComponentType<IconProps>): ReactElement => (
    <IconComponent color={isGStar ? primary : footer.color} hoverColor={footer.hover} />
  );

  const icons: { [k: string]: ReactElement } = {
    pinterest: applyIconProps(SocialPinterestInvertedIcon),
    linkedin: applyIconProps(SocialLinkedinPlainIcon),
    facebook: applyIconProps(SocialFacebookPlainIcon),
    youtube: applyIconProps(SocialYoutubePlainIcon),
    email: applyIconProps(SocialEmailInvertedIcon),
    twitter: applyIconProps(SocialTwitterInvertedIcon),
    instagram: applyIconProps(SocialInstagramPlainIcon),
    tiktok: applyIconProps(SocialTiktokPlainIcon),
  };

  return (
    <>
      <S.Wrapper data-testid="footer-social-share">
        {socialMediaChannels.map((socialMediaChannel, i) => (
          <S.Link
            href={socialMediaChannel.url || '#'}
            aria-label={socialMediaChannel.code || ''}
            target={socialMediaChannel.target === LinkTarget.Newwindow ? '_blank' : '_self'}
            key={i}
          >
            <S.Icon>{socialMediaChannel.code ? icons[socialMediaChannel.code] : 'No Icon'}</S.Icon>
          </S.Link>
        ))}
      </S.Wrapper>
    </>
  );
};
