import type { ReactElement } from 'react';
import { useState, useEffect } from 'react';

import { Banner } from '../unmanaged/templates/Banner';
import { useUserContext } from '../../utilities/context/dynamic/UserContext';
import { useAppContext } from '../../utilities/context/static/AppContext';
import { useStaticContext } from '../../utilities/context/static/StaticContext';
import { getPpeJourneyContent } from '../../utilities/helpers';
import type { Title as SimpleBannerSchema } from '../../amplienceTypes/schemas/exported/simple-banner-schema';

export const PpeBanner = (): ReactElement => {
  const { user } = useUserContext();
  const { language } = useAppContext();
  const { ppeContent } = useStaticContext();
  const [bannerContent, setBannerContent] = useState<SimpleBannerSchema>();

  useEffect(() => {
    if (!user || !language) {
      return;
    }

    const handlePpeJourney = (language: string) => {
      const { expirationDate, formattedValue, trafficSource } = user.personalisedDiscount ?? {};

      if (!expirationDate || bannerContent?.bannerContent) {
        return;
      }

      const content = getPpeJourneyContent({
        ppeContent,
        trafficSource,
        expirationDate,
        formattedValue,
        language,
      });

      if (content?.bannerContent) {
        setBannerContent(content?.bannerContent);
      }
    };

    handlePpeJourney(language);
  }, [user, language, bannerContent?.bannerContent, ppeContent]);

  return bannerContent ? <Banner className="ppeBanner" {...bannerContent} /> : <></>;
};
