import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const WishlistIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M13.937 3.563a3.63 3.63 0 0 0-5.12-.007L8 4.316l-.818-.762a3.625 3.625 0 0 0-5.12 5.134l5.585 5.666a.5.5 0 0 0 .712 0l5.578-5.666a3.625 3.625 0 0 0 0-5.125Zm-.709 4.422L8 13.287 2.769 7.98A2.625 2.625 0 0 1 6.48 4.268l.013.012 1.165 1.084a.5.5 0 0 0 .682 0L9.506 4.28l.013-.012a2.625 2.625 0 0 1 3.71 3.715l-.001.002Z"
    />
  </Svg>
);

export default WishlistIcon;
