import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialLinkedinPlainIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg width={17} height={16} viewBox="0 0 17 16" $hoverColor={hoverColor}>
    <path
      fill={color}
      d="M4.258 5.258h-3.5v10.25h3.5V5.258ZM2.498.422C1.499.422.5 1.172.5 2.172s.999 1.75 1.998 1.75c1 0 1.998-.75 1.998-1.75S3.497.422 2.498.422ZM6.008 5.258v10.25h3.496l.003-6.195c0-.672.852-1.616 1.801-1.616 1.24 0 1.695.96 1.695 2.365v5.446H16.5V9.563c0-3.213-1.742-4.5-3.996-4.5-1.817 0-2.541 1.037-2.997 1.75V5.258h-3.5Z"
    />
  </Svg>
);

export default SocialLinkedinPlainIcon;
