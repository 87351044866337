import type { ReactElement } from 'react';
import { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'react-fast-compare';
import { LoginButton } from '../login/LoginButton';
import { media } from '../../core/styles';
import { SideNavItem } from './SideNavItem';
import { MessageBoxNotification } from '../messageBox/MessageBoxNotification';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { getCategoryPathsFromPDPUrl } from '../../../../utilities/ssr';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { getActiveNavItems } from '../../../../utilities/helpers';
import type { GlobalMessageBoxNotification } from '../../../../amplienceTypes/schemas/imported/global-message-box-notification-schema';
import { useProductListContext } from '../../../../utilities/context/static/ProductListContext';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

interface SideNavigationProps {
  loginLabel?: string;
  messageBoxNotification?: GlobalMessageBoxNotification;
}

const S = {
  SideNavContainer: styled.div<{ $isGStar: boolean }>`
    ${({ theme }) => css`
      background-color: ${theme.colors.background.base};
      box-sizing: border-box;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding-bottom: 54px;
      overflow: hidden;

      &:hover,
      &:focus {
        overflow-y: auto;
      }

      @media ${media(theme).lessThan('sm')} {
        overflow-y: scroll;
      }
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          border-right: 1px solid ${theme.colors.border.mid};
        `,
      })}
    `}
  `,

  LoginPanel: styled.div`
    ${({ theme }) => css`
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: ${theme.colors.background.subtle};
      padding: ${`${theme.padding.none} ${theme.padding['2xs']}`};
      box-sizing: border-box;
      border-top: 1px solid ${theme.colors.border.subtle};

      @media ${media(theme).greaterThan('sm')} {
        display: none;
      }
    `}
  `,
};

const SideNavigationComponent = ({
  loginLabel,
  messageBoxNotification,
  openedModal,
  setOpenedModal,
}: SideNavigationProps & HeaderOpenModalProps): ReactElement => {
  const {
    product: { url },
  } = usePDPContext();
  const { categoryDetails, isPLP } = useProductListContext();
  const {
    leftNavigation,
    configuration: { showMessageBoxNotifications },
  } = useStaticContext();
  const { isGStar, locale } = useAppContext();
  const { plpPath } = getCategoryPathsFromPDPUrl(url);
  // Because fit filtered requestUrl looks like this /en_nl/shop/men/jeans/skinny/fit/skinny
  const filteredPLPPath = categoryDetails?.requestUrl?.includes('/fit/')
    ? categoryDetails?.requestUrl?.split('/').slice(0, -2).join('/')
    : categoryDetails?.requestUrl;

  // TODO: use localizeUrl from useAppContext when merged instead of prefixing with locale
  const plpPathUrl = isPLP ? filteredPLPPath || '' : `/${locale}${plpPath}`;
  const activeNodeInfo = getActiveNavItems(leftNavigation, plpPathUrl);
  const [activeIndexArray, setActiveIndexArray] = useState(activeNodeInfo?.indexArray ?? []);
  const activeItemUrl = activeNodeInfo?.navItem?.url;

  return (
    <S.SideNavContainer data-cs-capture="" $isGStar={isGStar}>
      {leftNavigation.navigation?.map((item, index) => (
        <SideNavItem
          {...item}
          key={index}
          deepLevel={0}
          activeIndexArray={activeIndexArray}
          currentIndex={`${index}`}
          updateActiveItem={setActiveIndexArray}
          activeItemUrl={activeItemUrl}
        />
      ))}
      {isGStar && (
        <S.LoginPanel data-cs-capture="">
          <LoginButton loginLabel={loginLabel} />
          {messageBoxNotification && showMessageBoxNotifications && (
            <MessageBoxNotification
              {...messageBoxNotification}
              openedModal={openedModal}
              setOpenedModal={setOpenedModal}
            />
          )}
        </S.LoginPanel>
      )}
    </S.SideNavContainer>
  );
};

export const SideNavigation = memo(SideNavigationComponent, isEqual);
