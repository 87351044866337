import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SvgComponent = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M14.432 13.25c-.951-1.646-2.418-2.826-4.13-3.385a4.5 4.5 0 1 0-4.604 0c-1.712.559-3.179 1.739-4.13 3.385a.5.5 0 1 0 .865.5C3.611 11.715 5.692 10.5 8 10.5c2.308 0 4.39 1.215 5.567 3.25a.497.497 0 0 0 .69.194.5.5 0 0 0 .175-.694ZM4.5 6a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
    />
  </Svg>
);

export default SvgComponent;
