import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GenericLinkList } from './GenericLinkList';
import { media } from '../../core/styles';
import type { Navigation } from '~/utilities/graphql/codegen';
import { NavigationTypeParam, SiteType } from '~/utilities/graphql/codegen';
import { fetchNavigation } from '~/utilities/graphql/queries';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';
import { getSiteStyles } from '~/utilities/helpers';

const S = {
  Wrapper: styled.div`
    ${({ theme }) => css`
      @media ${media(theme).greaterThan('sm')} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @media ${media(theme).greaterThan('lg')} {
        padding-left: 0;
        padding-right: 0;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          display: flex;
          flex-direction: column;
          gap: 24px;

          @media ${media(theme).greaterThan('sm')} {
            flex-grow: 2;
            justify-content: left;
            flex-flow: row nowrap;
          }
        `,
        [SiteType.Aaf]: css`
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media ${media(theme).greaterThan('sm')} {
            min-width: 30%;
            flex-direction: row;
            margin: 0;
          }
        `,
      })}
    `}
  `,
};

const GenericLinkPanelComponent = (): ReactElement => {
  const [footerNavigationData, setFooterNavigationData] = useState<Navigation>({});
  const { locale, siteType } = useAppContext();
  const { client: apolloClient } = useApolloContext();

  useEffect(() => {
    const getFooterNavigation = async () => {
      const { navigation } = await fetchNavigation({
        siteType,
        locale,
        navigationType: NavigationTypeParam.FooterNavigation,
        apolloClient,
      });

      setFooterNavigationData(navigation || {});
    };

    getFooterNavigation();
  }, [locale, siteType, apolloClient]);

  return (
    <S.Wrapper data-testid="footer-linklist-panel">
      {footerNavigationData?.navigation?.map((panelInfo, index) => (
        <GenericLinkList {...panelInfo} key={index} />
      ))}
    </S.Wrapper>
  );
};

export const GenericLinkPanel = GenericLinkPanelComponent;
