import type { ReactElement } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { media } from '../../core/styles';
import ShoppingBagIcon from '../../core/icons/ShoppingBagIcon';
import { Typography } from '../../core/typography/Typography';
import { useCartContext } from '~/utilities/context/dynamic/CartContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import BagIcon from '../../core/icons/BagIcon';
import { HeaderButton } from '../HeaderStyle';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

const S = {
  ShoppingCartButton: styled.button`
    ${({ theme }) => css`
      ${HeaderButton}

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          &:hover,
          &:focus {
            path {
              fill: ${theme.colors.content.component.header.hover};
            }

            span {
              color: ${theme.colors.content.component.header.hover};
            }
          }
        `,
      })}
    `}
  `,

  Amount: styled(Typography)`
    ${({ theme }) => css`
      color: ${theme.colors.content.component.header.color};

      ${getSiteStyles(theme.siteType, {
        [SiteType.Aaf]: css`
          font-weight: 400;
        `,
      })}
    `}
  `,

  IconWrapper: styled.span`
    ${({ theme }) => css`
      display: inline-block;
      width: ${theme.size[4]};
      height: ${theme.size[4]};

      @media ${media(theme).greaterThan('sm')} {
        width: ${theme.siteType === SiteType.Gstar ? '16px' : '18px'};
        height: ${theme.siteType === SiteType.Gstar ? '16px' : '18px'};
      }
    `}
  `,
};

export const ShoppingCartButton = (): ReactElement => {
  const { setCartHovered, miniCart } = useCartContext();
  const theme = useTheme();
  const { isGStar, locale } = useAppContext();

  const handleClick = () => {
    window.location.href = `/${locale}/checkout/shopping-bag`;
  };

  return (
    <S.ShoppingCartButton
      onMouseEnter={() => setCartHovered(true)}
      onFocus={() => setCartHovered(true)}
      onMouseLeave={() => setCartHovered(false)}
      onBlur={() => setCartHovered(false)}
      onClick={handleClick}
      aria-label={`Shopping bag contains ${miniCart.totalItems || '0'} ${
        (miniCart.totalItems || 0) === 1 ? 'item' : 'items'
      }`}
      data-cs-capture=""
    >
      <S.IconWrapper data-testid="mini-cart-icon">
        {isGStar ? (
          <ShoppingBagIcon color={theme.colors.content.component.header.color} />
        ) : (
          <BagIcon color={theme.colors.content.component.header.color} />
        )}
      </S.IconWrapper>
      {!!miniCart?.totalItems && (
        <S.Amount component="span" variant="title" testId="mini-cart-count" data-cs-capture="">
          {miniCart.totalItems}
        </S.Amount>
      )}
    </S.ShoppingCartButton>
  );
};
