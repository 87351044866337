import styled, { css } from 'styled-components';
import type { ReactElement } from 'react';
import { media } from '../../core/styles';
import { spacing } from '../../../shared/core/styles';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

const S = {
  MessageContainer: styled.div`
    ${({ theme }) => css`
      display: flex;
      justify-content: flex-start;
      border: 1px solid ${theme.colors.content.component.header.modal.color};
      padding: ${spacing.XXXS} ${spacing.XXS};
      margin: ${spacing.XXS} ${spacing.XXS} ${spacing.XS};
      background-color: ${theme.colors.content.component.header.modal.base};
      color: ${theme.colors.content.component.header.modal.color};
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
      text-align: left;

      @media ${media(theme).greaterThan('sm')} {
        margin: 0 0 ${spacing.XS};
      }
    `}
  `,

  Text: styled.p`
    margin: 0;
  `,
};

interface CartUnreservedItemsComponentProps {
  text: string | undefined | null;
}

const CartUnreservedItemsComponent = ({
  text,
}: CartUnreservedItemsComponentProps): ReactElement => {
  const {
    configuration: { enableCartItemsNotReservedMessage },
  } = useStaticContext();

  if (!enableCartItemsNotReservedMessage || !text) {
    return <></>;
  }

  return (
    <S.MessageContainer>
      <S.Text
        dangerouslySetInnerHTML={{ __html: text }}
        data-testid="mini-cart-not-reserved-message"
      />
    </S.MessageContainer>
  );
};

export default CartUnreservedItemsComponent;
