import type { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { NavigationType, SiteType } from '~/utilities/graphql/codegen';
import { media } from '../../core/styles';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import { useNavActionContext } from '~/utilities/context/dynamic/NavActionContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import HamburgerIcon from '../../core/icons/HamburgerIcon';
import CloseIcon from '../../core/icons/CloseModalIcon';
import { getSiteStyles } from '~/utilities/helpers';

const S = {
  HamburgerButton: styled.button<{ $isFlyout: boolean }>`
    ${({ theme, $isFlyout }) => css`
      border: 0;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 0;
      height: var(--top-nav-height);
      width: ${theme.siteType === SiteType.Gstar ? '56px' : '54px'};
      display: inline-block;
      position: relative;

      @media ${media(theme).greaterThan('lg')} {
        display: ${$isFlyout ? 'none' : 'inline-block'};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          width: 56px;
          padding: 16px;
        `,
      })}
    `}
  `,
  HamburgerIcon: styled.span`
    ${({ theme }) => css`
      position: absolute;
      display: inline-block;
      height: 14px;
      left: calc(50% - 9px);
      top: calc(50% - 7px);
      width: 18px;

      @media ${media(theme).greaterThan('sm')} {
        height: 18px;
        width: 24px;
        left: calc(50% - 12px);
        top: calc(50% - 9.5px);
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('sm')} {
            height: 14px;
            width: 18px;
            left: calc(50% - 9px);
            top: calc(50% - 7px);
          }
        `,
        [SiteType.Aaf]: css`
          @media ${media(theme).greaterThan('sm')} {
            top: calc(50% - 5px);
          }
        `,
      })}
    `}
  `,
  Stick: styled.span<{ $opened: boolean }>`
    ${({ theme, $opened }) => css`
      background-color: ${theme.colors.content.component.header.color};
      display: block;
      height: 2px;
      margin: ${theme.padding['3xs']} ${theme.padding.none};
      transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1) 0.3s;
      width: 100%;
      opacity: 1;

      &:first-child {
        margin-top: 0;
        ${$opened && `transform: translate(0, 6px) rotate(45deg);`}
      }

      &:nth-child(2) {
        ${$opened && `opacity: 0;`}
      }

      &:last-child {
        margin-bottom: 0;
        ${$opened && `transform: translate(0, -6px) rotate(-45deg);`}
      }

      @media (prefers-reduced-motion) {
        transition: none;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('sm')} {
            height: 2px;
            margin: 4px 0;

            &:first-child {
              ${$opened && `transform: translate(0, 6px) rotate(45deg);`}
            }

            &:last-child {
              ${$opened && `transform: translate(0, -6px) rotate(-45deg);`}
            }
          }

          @media ${media(theme).greaterThan('xl')} {
            &:first-child,
            &:last-child {
              transform: none;
            }

            &:nth-child(2) {
              opacity: 1;
            }
          }
        `,
        [SiteType.Aaf]: css`
          @media ${media(theme).greaterThan('sm')} {
            height: 2px;
            margin: 5px 0;

            &:first-child {
              ${$opened && `transform: translate(0, 7px) rotate(45deg);`}
            }

            &:last-child {
              ${$opened && `transform: translate(0, -7px) rotate(-45deg);`}
            }
          }
        `,
      })}
    `}
  `,
  CloseIconWrapper: styled.div<{ $opened: boolean }>`
    ${({ theme, $opened }) => css`
      ${$opened ? 'display: block;' : 'display: none;'}

      @media ${media(theme).greaterThan('xl')} {
        display: none;
      }
    `}
  `,
  HamburgerIconWrapper: styled.div<{ $opened: boolean }>`
    ${({ theme, $opened }) => css`
      ${$opened ? 'display: none;' : 'display: block;'}

      @media ${media(theme).greaterThan('xl')} {
        display: block;
      }
    `}
  `,
};

export const HamburgerButton = (): ReactElement => {
  const { sideNavOpened, toggleSideNav } = useNavActionContext();
  const {
    configuration: { navigationType },
    accessibilityLabels: { openSideBar, closenSideBar } = {},
  } = useStaticContext();
  const { isGStar } = useAppContext();
  const theme = useTheme();

  return (
    <S.HamburgerButton
      $isFlyout={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      suppressHydrationWarning
      onClick={toggleSideNav}
      data-testid="hamburger-menu"
      aria-label={sideNavOpened ? closenSideBar : openSideBar}
      aria-controls="sideNav"
      id="hamburger-menu"
    >
      {isGStar && (
        <>
          <S.CloseIconWrapper $opened={sideNavOpened}>
            <CloseIcon color={theme.colors.content.component.header.color} />
          </S.CloseIconWrapper>
          <S.HamburgerIconWrapper $opened={sideNavOpened}>
            <HamburgerIcon color={theme.colors.content.component.header.color} />
          </S.HamburgerIconWrapper>
        </>
      )}
      {!isGStar && (
        <S.HamburgerIcon>
          <S.Stick suppressHydrationWarning $opened={sideNavOpened} />
          <S.Stick suppressHydrationWarning $opened={sideNavOpened} />
          <S.Stick suppressHydrationWarning $opened={sideNavOpened} />
        </S.HamburgerIcon>
      )}
    </S.HamburgerButton>
  );
};
