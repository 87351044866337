import { Environments } from '.';
import { SiteType } from '../graphql/codegen';

// Audioeye Hashes
const aeGstarStagingHash = 'cd5e0739ae13f3e960b5de4482c8c86f';
const aeGstarProductionHash = 'c8f36ff6df92592d93859d81570c323b';
const aeAafStagingHash = 'e8370e40491280f7660a782d337fabbd';
const aeAafProductionHash = '316559997aabfd8c88fd0c06d6d9e3c0';

export const audioEyeHashes: { [k: string]: { [k: string]: string } } = {
  [SiteType.Aaf]: {
    [Environments.DEV]: aeAafStagingHash,
    [Environments.TST]: aeAafStagingHash,
    [Environments.ACC]: aeAafStagingHash,
    [Environments.PRD]: aeAafProductionHash,
  },
  [SiteType.Gstar]: {
    [Environments.DEV]: aeGstarStagingHash,
    [Environments.TST]: aeGstarStagingHash,
    [Environments.ACC]: aeGstarStagingHash,
    [Environments.PRD]: aeGstarProductionHash,
  },
};

export const getAudioEyeHash = (siteType: SiteType, environment: Environments) => {
  if (siteType === SiteType.Aaf) {
    return audioEyeHashes[SiteType.Aaf][environment];
  }

  return audioEyeHashes[SiteType.Gstar][environment];
};
