import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import FocusLock from 'react-focus-lock';
import { media, colors } from '../core/styles';
import { Button } from '../core/button/Button';
import { Radio, Checkbox, InputField, RadioGroup } from '../core/form';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { PRIVACY_POLICY_VARIABLE } from '../../../utilities/constants/amplienceVariables';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { getFormSchema } from '../../../utilities/constants/formSchema';
import { subscribeToGstar } from '../../../utilities/helpers';
import { EntryPoint } from '../../../utilities/graphql/codegen';
import type { GlobalNewsletterOverlay as GlobalNewsletterOverlaySchema } from '../../../amplienceTypes/schemas/imported/global-newsletter-overlay-schema';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import createImageUrlFromLinkImage from '../../../utilities/campaign/utils/createImageUrlFromLinkImage';
import GStarLogo from '../core/icons/GStarLogo';
import { useNewsletterOverlay } from '../../../utilities/hooks/useNewsletterOverlay';
import { useMediaQueryContext } from '../../../utilities/context/dynamic/MediaQueryContext';

type NewsletterType = {
  email: string;
  preferences: string;
  notification: string;
  updates: boolean;
  kidswear: boolean;
};

interface IImageObject {
  defaultHost: string;
  endpoint: string;
  name: string;
  id: string;
}

const boxSizing = css`
  box-sizing: border-box;
`;

const stepWrapper = css`
  ${boxSizing}
  display: flex;
  text-align: center;
  padding: 50px 20px;
  overflow-y: auto;
  flex-wrap: wrap;
  width: 100%;

  @media ${({ theme }) => media(theme).greaterThan('sm')} {
    padding: 50px 80px;
  }

  @media ${({ theme }) => media(theme).greaterThan('md')} {
    padding: 50px 100px;
  }
`;

const S = {
  Overlay: styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    inset: 0;
    overflow: hidden;
    background-color: ${colors.DIALOG_DARK_GREY};
    transition: background-color ease-in-out 250ms;
    z-index: 10008;
    min-height: auto;

    @media ${({ theme }) => media(theme).greaterThan('md')} {
      align-items: center;
    }
  `,

  Dialog: styled.div`
    ${boxSizing}
    display: flex;
    position: relative;
    background: ${colors.WHITE};
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: normal;
    text-decoration: inherit;
    cursor: default;
    margin: 30px 20px;
    width: 100%;
    max-width: 768px;

    @media ${({ theme }) => media(theme).greaterThan('md')} {
      max-width: 800px;
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      height: 100%;
      max-height: 600px;
    }
  `,

  Banner: styled.div<{ src: string }>`
    display: none;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      background-image: url(${({ src }) => src});
      flex: 0 0 320px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,

  LogoWrapper: styled.div`
    color: ${colors.BLACK};
    width: 176px;
    margin: 0 auto 40px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      color: ${colors.WHITE};
      width: 206px;
      margin: 0;
    }
  `,

  StepOne: styled.div`
    ${stepWrapper}
  `,

  StepTwo: styled.div`
    ${stepWrapper}
  `,

  StepContent: styled.div`
    ${boxSizing}
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `,

  SuperHeader: styled.span`
    ${boxSizing}
    font-size: 14px;
    font-weight: 900;
    color: ${colors.NERO_GREY};
    display: block;
    padding: 0 0 15px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
  `,

  Header: styled.span`
    ${boxSizing}
    font-size: 43px;
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-weight: 900;
    color: ${colors.NERO_GREY};
    display: block;
    padding: 0 0 15px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
  `,

  SubHeader: styled.p`
    ${boxSizing}
    color: ${colors.NERO_GREY};
    font-size: 16px;
    line-height: 1.7em;
    margin-bottom: 17px;
    font-weight: 600;
  `,

  ContinueWithoutHeader: styled.strong`
    ${boxSizing}
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: underline;
    font-size: 16px;
  `,

  StepTwoSuperHeader: styled.span`
    font-size: 22px;
    font-weight: 900;
    padding: 0 0 40px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;

    @media ${({ theme }) => media(theme).greaterThan('md')} {
      margin-left: -10px;
      margin-right: -10px;
    }
  `,

  StepTwoHeader: styled.p`
    ${boxSizing}
    color: ${colors.NERO_GREY};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 15px 0 40px;
    margin-bottom: 17px;
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
  `,

  ContinueBtn: styled(Button)`
    font-size: 13px;
    width: 100%;
    padding: 0 10px;
    height: 48px;
  `,

  SubmitBtn: styled(Button)`
    display: block;
    font-size: 14px;
    color: ${colors.WHITE};
    background: ${colors.NERO_GREY};
    border: none;
    cursor: pointer;
    width: 100%;
    height: 40px;
    text-align: center;
    margin-bottom: 20px;
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
  `,

  NotificationCheckbox: styled.div`
    text-align: left;

    label span {
      font-size: 10px;
    }
  `,

  PrivacyPolicyLink: styled.a`
    color: ${colors.ACCESSIBILITY_GREY};
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  Form: styled.form`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      text-align: inherit;
    }
  `,

  EmailSubscribe: styled.div`
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      flex-direction: row;
    }
  `,

  Email: styled.div`
    margin-top: 19px;
    margin-bottom: 12px;
    width: 100%;
    box-sizing: border-box;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      max-width: 390px;
      margin: 12px 0 0;
    }
  `,

  KidswearCheckbox: styled.div`
    text-align: left;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      text-align: inherit;
    }
  `,

  OverlayClose: styled.button`
    position: absolute;
    border: none;
    outline: none;
    width: 42px;
    height: 42px;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;

    &::before {
      color: ${colors.NERO_GREY};
      font-family: gstar-icons;
      content: '\\F13F';
    }
  `,
};

export const NewsletterOverlay = ({
  superHeader,
  header,
  subHeader,
  confirmationSuperHeader,
  confirmationHeader,
  continueButton,
  submitBtn,
  menswear,
  womenswear,
  kidswear,
  emailPlaceholder,
  description,
  privacyPolicy,
  continueWithoutOffer,
  bannerImgLink,
  showLogo,
}: GlobalNewsletterOverlaySchema) => {
  const { isMobile } = useMediaQueryContext();
  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);
  const { country, isGStar } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();

  const pushToDatalayerEvent = (action: string, event: string) => {
    const subscriptionPageTopic = 'topic_bf20Q4';

    pushToDataLayer({
      events: {
        category: 'newsletter',
        action,
        label: `NEWSLETTER_SUBSCRIPTION~${subscriptionPageTopic}`.toLowerCase(),
        newsletter_type: EntryPoint.NewsletterPopup,
      },
      page: {
        countryCode: country.toUpperCase(),
      },
      event,
    });
  };

  const onOpenEventHandler = () => {
    pushToDatalayerEvent('open', 'newsletter_open');
  };

  const { showNewsletterOverlay, closeNewsletterOverlay } =
    useNewsletterOverlay(onOpenEventHandler);

  const {
    product: { subTargetAudience },
  } = usePDPContext();

  const { t } = useTranslation('common');

  const {
    accessibilityLabels,
    formErrorLabels,
    configuration: { enableKidswearNewsletter },
  } = useStaticContext();
  const NewsletterSchema = getFormSchema({
    emailRequiredLabel:
      formErrorLabels?.emailRequiredLabel || t('globalFormErrorLabels.emailRequiredLabel'),
    emailInvalidLabel:
      formErrorLabels?.emailInvalidLabel || t('globalFormErrorLabels.emailInvalidLabel'),
    preferencesRequiredLabel:
      formErrorLabels?.preferencesRequiredLabel ||
      t('globalFormErrorLabels.preferencesRequiredLabel'),
    notificationRequiredLabel:
      formErrorLabels?.notificationRequiredLabel ||
      t('globalFormErrorLabels.notificationRequiredLabel'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<NewsletterType>();

  const onClose = () => {
    closeNewsletterOverlay();
    pushToDatalayerEvent('close', 'newsletter_close');
  };

  const handleSubscribe: SubmitHandler<NewsletterType> = async values => {
    const entryPointId: EntryPoint = EntryPoint.NewsletterPopup;

    try {
      await subscribeToGstar({
        isNewsLetterSubscription: true,
        entryPoint: entryPointId,
        email: values.email,
        preferredCategory: values.preferences,
      });
      setConfirmationVisible(true);
      pushToDatalayerEvent('subscribe', 'newsletter-subscribe');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const radios = [
    <Radio
      id="mens-fashion"
      label={menswear || t('globalNewsletterOverlay.menswear')}
      name="preferences"
      value="men"
      key="1"
      register={register}
      required={NewsletterSchema.preferences.required}
      error={errors.preferences && errors.preferences.message}
      testId="newsletter-preference-men"
      defaultChecked={subTargetAudience === 'men'}
      ariaLabel={accessibilityLabels?.manFashionPreference}
    />,
    <Radio
      id="womens-fashion"
      label={womenswear || t('globalNewsletterOverlay.womenswear')}
      name="preferences"
      value="women"
      key="2"
      register={register}
      required={NewsletterSchema.preferences.required}
      error={errors.preferences && errors.preferences.message}
      testId="newsletter-preference-women"
      defaultChecked={subTargetAudience === 'women'}
      ariaLabel={accessibilityLabels?.womanFashionPreference}
    />,
  ];

  const privacyPolicyEl = (
    <span>
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[0]}
      <S.PrivacyPolicyLink href="/help-info/privacy-policy">
        {privacyPolicy || t('globalNewsletter.privacyPolicy')}
      </S.PrivacyPolicyLink>
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[1]}
    </span>
  );

  const src = bannerImgLink
    ? createImageUrlFromLinkImage(bannerImgLink as IImageObject, 'fmt=auto')
    : undefined;

  if (!showNewsletterOverlay) {
    return null;
  }

  return (
    <FocusLock>
      <S.Overlay role="dialog">
        <S.Dialog>
          <S.OverlayClose
            type="button"
            aria-label="dialog close"
            className="close-dialog"
            onClick={onClose}
            data-testid="close-dialog"
          />

          {src && (
            <S.Banner src={src}>
              {showLogo && (
                <S.LogoWrapper>
                  <GStarLogo />
                </S.LogoWrapper>
              )}
            </S.Banner>
          )}

          {confirmationVisible ? (
            <S.StepTwo>
              <S.StepContent>
                <S.StepTwoSuperHeader>
                  {confirmationSuperHeader || t('globalNewsletterOverlay.confirmationSuperHeader')}
                </S.StepTwoSuperHeader>
                <S.StepTwoHeader>
                  {confirmationHeader || t('globalNewsletterOverlay.confirmationHeader')}
                </S.StepTwoHeader>
                <S.ContinueBtn
                  onClick={onClose}
                  label={continueButton || t('globalNewsletterOverlay.continueButton')}
                />
              </S.StepContent>
            </S.StepTwo>
          ) : (
            <S.StepOne>
              <S.StepContent>
                {showLogo && isMobile && (
                  <S.LogoWrapper>
                    <GStarLogo color={colors.BLACK} />
                  </S.LogoWrapper>
                )}
                <S.SuperHeader>
                  {superHeader || t('globalNewsletterOverlay.superHeader')}
                </S.SuperHeader>
                <S.Header>{header || t('globalNewsletterOverlay.header')}</S.Header>
                <S.SubHeader>{subHeader || t('globalNewsletterOverlay.subHeader')}</S.SubHeader>
                <S.Form onSubmit={handleSubmit(handleSubscribe)}>
                  {isGStar && radios && (
                    <RadioGroup
                      label=""
                      radios={radios}
                      error={errors.preferences && errors.preferences.message}
                      testId="newsletter-preference"
                      data-cs-capture=""
                      role="radiogroup"
                    />
                  )}
                  {enableKidswearNewsletter && (
                    <S.KidswearCheckbox>
                      <Checkbox
                        label={kidswear || t('globalNewsletterOverlay.kidswear')}
                        newsletter
                        name="kidswear"
                        register={register}
                        testId="newsletter-kidswear"
                        data-cs-capture=""
                        isChecked="false"
                      />
                    </S.KidswearCheckbox>
                  )}
                  <S.EmailSubscribe>
                    <S.Email>
                      <InputField
                        placeholder={
                          emailPlaceholder || t('globalNewsletterOverlay.emailPlaceholder')
                        }
                        name="email"
                        register={register}
                        required={NewsletterSchema.email.required}
                        pattern={NewsletterSchema.email.pattern}
                        showError
                        error={errors.email && errors.email.message}
                        testId="newsletter-email"
                      />
                    </S.Email>
                  </S.EmailSubscribe>
                  {privacyPolicyEl && (
                    <S.NotificationCheckbox>
                      <Checkbox
                        label={privacyPolicyEl}
                        newsletter
                        name="notification"
                        register={register}
                        required={NewsletterSchema.notification.required}
                        error={errors.notification && errors.notification.message}
                        testId="newsletter-privacy-policy"
                        data-cs-capture=""
                        isChecked={getValues().notification as 'true' | 'false'}
                      />
                    </S.NotificationCheckbox>
                  )}
                  <S.SubmitBtn
                    label={(submitBtn as string) || t('globalNewsletterOverlay.submitBtn')}
                    type="submit"
                    disabled={isSubmitting}
                    testId="newsletter-submit"
                    data-cs-capture=""
                  />
                </S.Form>
                <S.ContinueWithoutHeader onClick={onClose}>
                  {continueWithoutOffer || t('globalNewsletterOverlay.continueWithoutOffer')}
                </S.ContinueWithoutHeader>
              </S.StepContent>
            </S.StepOne>
          )}
        </S.Dialog>
      </S.Overlay>
    </FocusLock>
  );
};
