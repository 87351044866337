import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SearchIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 800.875 453.238281 C 800.875 645.234375 645.234375 800.875 453.238281 800.875 C 261.242188 800.875 105.601562 645.234375 105.601562 453.238281 C 105.601562 261.242188 261.242188 105.601562 453.238281 105.601562 C 645.234375 105.601562 800.875 261.242188 800.875 453.238281 Z M 734.226562 808.882812 C 656.992188 869.996094 559.375 906.476562 453.238281 906.476562 C 202.921875 906.476562 0 703.550781 0 453.238281 C 0 202.921875 202.921875 0 453.238281 0 C 703.550781 0 906.476562 202.921875 906.476562 453.238281 C 906.476562 559.367188 869.996094 656.980469 808.894531 734.214844 L 1008.550781 933.863281 C 1029.171875 954.484375 1029.171875 987.917969 1008.550781 1008.539062 C 987.929688 1029.152344 954.503906 1029.152344 933.882812 1008.539062 Z M 734.226562 808.882812 "
      />
    </g>
  </Svg>
);

export default SearchIcon;
