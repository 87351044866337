import type { ReactElement } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../../core/typography/Typography';
import { media } from '../../core/styles';
import { GenericLink } from './GenericLink';
import type { NavigationItem } from '~/utilities/graphql/codegen';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

const S = {
  Wrapper: styled.nav`
    ${({ theme }) => css`
      text-align: left;

      @media ${media(theme).greaterThan('sm')} {
        flex: 1;
        margin-inline: 5px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
          flex-grow: 0;
        }
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('sm')} {
            margin-inline: 0;
          }

          @media ${media(theme).greaterThan('xl')} {
            max-width: 176px;

            &:last-of-type {
              flex-grow: 1;
            }
          }
        `,
        [SiteType.Aaf]: css`
          media ${media(theme).lessThan('sm')} {
            margin-top: ${theme.padding.sm};
          }
        `,
      })}
    `}
  `,

  ListTitle: styled(Typography)<{ $active: boolean }>`
    ${({ theme, $active }) => css`
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;

      @media ${media(theme).lessThan('sm')} {
        cursor: pointer;

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          content: ' ';
          right: 0;
          width: 12px;
          height: ${theme.borders.width.sm};
          background-color: ${theme.colors.content.component['close-button']};
        }

        &::before {
          transform: rotate(${$active ? '0' : '90deg'});
          transition: transform 0.25s ease-in-out;

          @media (prefers-reduced-motion) {
            transition: none;
          }
        }
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 20px;
          font-weight: ${theme.overrides.font?.weight.medium};
          color: ${theme.colors.slate['900']};

          &::before,
          &::after {
            background-color: ${theme.colors.slate['600']};
          }

          @media ${media(theme).greaterThan('sm')} {
            padding-top: 0;
            padding-bottom: ${theme.padding.xs};
          }
        `,
        [SiteType.Aaf]: css`
          line-height: 100%;
          height: 10px;
          padding: ${theme.size[4]} ${theme.size[0]};
          color: ${theme.colors.content.component.footer.color};

          &::before,
          &::after {
            height: ${theme.borders.width.xs};
          }
        `,
      })}
    `}
  `,

  LinkList: styled.div<{ $active: boolean }>`
    ${({ theme, $active }) => css`
      margin-top: ${theme.size[1]};
      max-height: inherit;
      overflow: visible;

      @media ${media(theme).lessThan('sm')} {
        overflow: ${$active ? 'visible' : 'hidden'};
        max-height: ${$active ? '500px' : '0px'};
        transition: max-height 0.5s ease-in-out;
        transition: overflow 0.5s ease-in-out;
      }

      @media (prefers-reduced-motion) {
        transition: none;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: ${theme.size[3]};
          align-self: stretch;
          margin: 0;
          padding: 0;
        `,
      })}
    `}
  `,
};

export const GenericLinkList = ({ label, children }: NavigationItem): ReactElement => {
  const [isActive, setIsActive] = useState(false);

  return (
    <S.Wrapper data-testid="footer-linklist">
      <S.ListTitle
        component="h2"
        variant="title"
        $active={isActive}
        onClick={() => {
          setIsActive(!isActive);
        }}
        testId="footer-linklist-title"
      >
        {label}
      </S.ListTitle>
      <S.LinkList $active={isActive} data-testid="footer-linklist-list">
        {children && children.map((link, index) => <GenericLink {...link} key={index} />)}
      </S.LinkList>
    </S.Wrapper>
  );
};
