import type { ReactElement } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { media } from '../../core/styles';
import { useAppContext } from '~/utilities/context/static/AppContext';
import HeartEmptyIcon from '../../core/icons/HeartEmptyIcon';
import { Typography } from '../../core/typography/Typography';
import { useWishlistContext } from '~/utilities/context/dynamic/WishlistContext';
import { useDataLayerContext } from '~/utilities/context/static/DataLayerContext';
import WishlistIcon from '../../core/icons/WishlistIcon';
import { HeaderButton } from '../HeaderStyle';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

interface WishlistButtonProps {
  onWishlistHover: (hovered: boolean) => void;
}

const S = {
  WishListButton: styled.button`
    ${({ theme }) => css`
      ${HeaderButton}
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          &:hover,
          &:focus {
            path {
              fill: ${theme.colors.content.component.header.hover};
            }

            span {
              color: ${theme.colors.content.component.header.hover};
            }
          }
        `,
      })}
    `}
  `,

  Amount: styled(Typography)`
    ${({ theme }) => css`
      color: ${theme.colors.content.component.header.color};

      ${getSiteStyles(theme.siteType, {
        [SiteType.Aaf]: css`
          font-weight: 400;
        `,
      })}
    `}
  `,

  IconWrapper: styled.span`
    ${({ theme }) => css`
      display: inline-block;
      width: ${theme.size[4]};
      height: ${theme.size[4]};

      ${getSiteStyles(theme.siteType, {
        [SiteType.Aaf]: css`
          @media ${media(theme).greaterThan('sm')} {
            width: ${theme.size[5]};
            height: ${theme.size[5]};
          }
        `,
      })}
    `}
  `,
};

export const WishListButton = ({ onWishlistHover }: WishlistButtonProps): ReactElement => {
  const { miniWishlist } = useWishlistContext();
  const { pushToDataLayer } = useDataLayerContext();
  const theme = useTheme();
  const { isGStar, locale } = useAppContext();

  const handleClick = () => {
    window.location.href = `/${locale}/wishlist`;
  };

  const openWishlist = () => {
    onWishlistHover(true);
    pushToDataLayer({
      events: {
        category: 'wishlist',
        action: 'hover',
        label: 'open mini-wishlist',
      },
      event: 'mini-wishlist-open',
    });
  };

  const hideMiniwishlist = () => {
    onWishlistHover(false);
  };

  return (
    <S.WishListButton
      onMouseEnter={openWishlist}
      onMouseLeave={hideMiniwishlist}
      onFocus={openWishlist}
      onBlur={hideMiniwishlist}
      onClick={handleClick}
      aria-label={`Wishlist contains ${miniWishlist?.products?.length || '0'} ${
        (miniWishlist?.products?.length || 0) === 1 ? 'item' : 'items'
      }`}
      data-cs-capture=""
    >
      {isGStar && (
        <S.IconWrapper data-testid="wishlist-icon">
          <HeartEmptyIcon color={theme.colors.content.component.header.color} />
        </S.IconWrapper>
      )}

      {!isGStar && (
        <S.IconWrapper data-testid="wishlist-icon">
          <WishlistIcon color={theme.colors.content.component.header.color} />
        </S.IconWrapper>
      )}

      {miniWishlist?.products && miniWishlist.products.length > 0 && (
        <S.Amount component="span" variant="title" testId="wishlist-count" data-cs-capture="">
          {miniWishlist.products?.length}
        </S.Amount>
      )}
    </S.WishListButton>
  );
};
