import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';
import { colors, media } from '../core/styles';
import { Typography } from '../core/typography/Typography';
import type { GlobalCookieNotice as GlobalCookieNoticeSchema } from '../../../amplienceTypes/schemas/imported/global-cookie-notice-schema';
import { useNavActionContext } from '../../../utilities/context/dynamic/NavActionContext';

interface CookieNoticeProps {
  showCookieNotice: boolean;
  close: () => void;
}

const S = {
  CookieNoticeWrapper: styled.div<{ $sideNavOpen: boolean; $showCookieNotice: boolean }>`
    ${({ theme, $sideNavOpen, $showCookieNotice }) => css`
      display: flex;
      justify-content: center;
      position: fixed;
      right: 0;
      left: ${$sideNavOpen ? 'var(--side-nav-width)' : 0};
      bottom: ${$showCookieNotice ? theme.size[3] : 0};
      visibility: ${$showCookieNotice ? 'visible' : 'hidden'};
      z-index: 506;
      transition: left 0.25s ease-in-out, bottom 0.15s ease, visibility 0s;
      will-change: bottom, visibility;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    `}
  `,

  CookieNotice: styled(Typography)`
    ${({ theme }) => css`
      display: inline-block;
      max-width: 90%;
      padding: 10px 24px;
      line-height: 1.6;
      background-color: ${colors.BLURRED_NERO_GREY};

      @media ${media(theme).greaterThan('sm')} {
        max-width: 956px;
      }
    `}
  `,

  Link: styled.a`
    text-decoration: underline;
    color: ${colors.WHITE};
    font-weight: 700;

    &:hover,
    &:focus {
      color: ${colors.ACCESSIBILITY_GREY};
    }
  `,

  Close: styled.button`
    border: none;
    outline: none;
    background-color: ${colors.NERO_GREY};
    width: 41px;
    height: 41px;
    cursor: pointer;

    &::before {
      color: ${colors.WHITE};
      font-family: gstar-icons;
      font-size: 20px;
      content: '\\F13F';
    }

    &:hover::before,
    &:focus::before {
      color: ${colors.ACCESSIBILITY_GREY};
    }
  `,
};

export const CookieNotice = ({
  notice,
  cookiesLink,
  close,
  showCookieNotice = false,
}: CookieNoticeProps & GlobalCookieNoticeSchema): ReactElement => {
  const { sideNavOpened } = useNavActionContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalCookieNotice' });

  return (
    <S.CookieNoticeWrapper
      suppressHydrationWarning
      $sideNavOpen={sideNavOpened}
      $showCookieNotice={showCookieNotice}
    >
      <S.CookieNotice color={colors.WHITE} variant="subtitle" testId="cookie-notice">
        {notice || t('notice')}&nbsp;
        <S.Link href="/cookie-statement">{cookiesLink || t('cookiesLink')}</S.Link>
      </S.CookieNotice>
      <S.Close aria-label="Close" onClick={close} data-testid="cookie-notice-close" />
    </S.CookieNoticeWrapper>
  );
};
