import type { ReactElement } from 'react';
import { memo, useState, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { getCookie } from 'cookies-next';
import { media } from '../../core/styles';
import LoginIcon from '../../core/icons/LoginIcon';
import UserIcon from '../../core/icons/UserIcon';
import type { Navigation } from '../../../../utilities/graphql/codegen';
import {
  CustomerType,
  NavigationType,
  NavigationTypeParam,
} from '../../../../utilities/graphql/codegen';
import { fetchNavigation } from '../../../../utilities/graphql/queries';
import { Typography } from '../../core/typography/Typography';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { HeaderButton } from '../HeaderStyle';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';
import { getSiteStyles } from '~/utilities/helpers';
import { SiteType } from '~/utilities/graphql/codegen';

interface LoginButtonProps {
  loginLabel?: string;
  myAccountLabel?: string;
}

const S = {
  LoginButton: styled.a`
    ${({ theme }) => css`
      line-height: var(--top-nav-height);
      ${HeaderButton}

      &:hover,
    &:focus {
        path {
          fill: ${theme.colors.content.component.header.hover};
        }

        span {
          border-color: ${theme.colors.content.component.header.color};
          color: ${theme.colors.content.component.header.color};
        }
      }
    `}
  `,

  IconWrapper: styled.span`
    display: inline-block;
    min-width: 16px;
    max-width: 16px;
    height: 100%;
  `,

  Title: styled.span`
    ${({ theme }) => css`
      color: ${theme.colors.content.component.header.color};
      max-width: 115px;
      line-height: normal;
      font-size: 14px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 7px;

      @media ${media(theme).greaterThan('sm')} {
        display: none;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          font-weight: 600;
        `,
      })}
    `}
  `,

  ProfileContainer: styled.div<{ $show: boolean }>`
    ${({ theme, $show }) => css`
      position: absolute;
      top: 54px;
      right: 0;
      width: 246px;
      background: ${theme.colors.content.component.header.color};
      box-shadow: 0 0 18px 0 rgb(0 0 0 / 20%);
      padding: 20px 46px 40px;
      text-align: left;
      visibility: ${$show ? 'visible' : 'hidden'};
      transform: ${$show ? 'translateY(0)' : 'translateY(-600px)'};
      transition: transform 0.2s linear, visibility 0s linear 0.2s;
      z-index: -1;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    `}
  `,

  AccountTitle: styled(Typography)`
    line-height: 20px;
    padding: 20px 16px;
    text-transform: uppercase;
    display: block;
  `,

  AccountLink: styled.a`
    ${({ theme }) => css`
      line-height: 1em;
      text-transform: capitalize;
      color: ${theme.colors.content.component.header.accessibility};
      display: block;
      padding: 9px 5px 9px 16px;
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${theme.colors.content.component.header.highLight};
      }
    `}
  `,
};

const LoginButtonComponent = ({ loginLabel, myAccountLabel }: LoginButtonProps): ReactElement => {
  const [accountDropdownData, setAccountDropdownData] = useState<Navigation>({});
  const [firstNameFromCookie, setFirstNameFromCookie] = useState<string>();

  const { locale, siteType, isGStar } = useAppContext();
  const { client: apolloClient } = useApolloContext();
  const {
    configuration: { navigationType },
  } = useStaticContext();
  const { flyoutItem, setFlyoutItem } = useNavActionContext();
  const { user } = useUserContext();
  const theme = useTheme();

  useEffect(() => {
    const firstName = getCookie('firstName')?.toString();

    if (firstName) {
      setFirstNameFromCookie(firstName);
    }
  }, []);

  const setMyAccount = () => {
    if (navigationType === NavigationType.HorizontalMainNavigationFlyOut) {
      setFlyoutItem(NavigationTypeParam.FlyoutMyAccountNavigation);
    }
  };

  useEffect(() => {
    if (navigationType === NavigationType.HorizontalMainNavigationFlyOut) {
      const getMyAccountNavigationData = async () => {
        const { navigation } = await fetchNavigation({
          siteType,
          locale,
          navigationType: NavigationTypeParam.FlyoutMyAccountNavigation,
          apolloClient,
        });

        setAccountDropdownData(navigation ?? {});
      };

      getMyAccountNavigationData();
    }
  }, [locale, navigationType, siteType, apolloClient]);

  // TODO: icon colors
  return (
    <>
      <S.LoginButton
        href={
          user?.customerType === CustomerType.Registered
            ? `/${locale}/account`
            : `/${locale}/account/create`
        }
        data-testid="login-button"
        onMouseEnter={setMyAccount}
        onMouseLeave={() => setFlyoutItem('')}
        data-cs-capture=""
      >
        <S.IconWrapper>
          {isGStar ? (
            <LoginIcon color={theme.colors.content.component.header.color} />
          ) : (
            <UserIcon />
          )}
        </S.IconWrapper>
        {isGStar && (
          <S.Title>
            {(() => {
              if (!user) {
                return firstNameFromCookie;
              }

              if (user.customerType === CustomerType.Registered) {
                return user.firstName || user.name;
              }

              return loginLabel;
            })()}
          </S.Title>
        )}
      </S.LoginButton>
      {flyoutItem === NavigationTypeParam.FlyoutMyAccountNavigation && (
        <S.ProfileContainer
          $show={!!accountDropdownData}
          onMouseEnter={setMyAccount}
          onMouseLeave={() => setFlyoutItem('')}
        >
          <S.AccountTitle component="span" variant="title">
            {myAccountLabel}
          </S.AccountTitle>
          {user?.customerType === CustomerType.Registered &&
            navigationType === NavigationType.HorizontalMainNavigationFlyOut &&
            accountDropdownData.navigation?.map((dropdownItem, i) => {
              if (dropdownItem?.children) {
                return dropdownItem.children.map((item, j) =>
                  item?.url && item?.label ? (
                    <S.AccountLink href={`/${locale}${item.url}`} key={j}>
                      {item.label}
                    </S.AccountLink>
                  ) : (
                    <></>
                  )
                );
              }

              return dropdownItem?.url && dropdownItem?.label ? (
                <S.AccountLink href={`/${locale}${dropdownItem.url}`} key={i}>
                  {dropdownItem.label}
                </S.AccountLink>
              ) : (
                <></>
              );
            })}
        </S.ProfileContainer>
      )}
    </>
  );
};

export const LoginButton = memo(LoginButtonComponent);
