import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import aa from 'search-insights';
import type { NavigationItem } from '~/utilities/graphql/codegen';
import { CustomerType, SiteType } from '~/utilities/graphql/codegen';
import { media } from '../../core/styles';
import {
  getClassNames,
  getStyleObject,
  storageAvailable,
  getSiteStyles,
} from '~/utilities/helpers';
import { useUserContext } from '~/utilities/context/dynamic/UserContext';
import {
  CART_ENTRIES,
  LOGGED_IN_STATUS,
  RECENTLY_VIEWED_PRODUCTS,
} from '~/utilities/constants/localStorageKeys';
import { Typography } from '../../core/typography/Typography';

export interface SideNavItemProps extends NavigationItem {
  deepLevel: number;
  activeIndexArray?: string[];
  activeItemUrl?: string | null;
  currentIndex: string;
  parentActive?: boolean;
  updateActiveItem: (arr: string[]) => void;
}

const style = {
  CloseButton: css`
    ${({ theme }) => `
      position: absolute;
      top: 50%;
      content: ' ';
      right: ${theme.size[5]};
      margin-top: 0;
      width: ${theme.size.component['close-button']};
      height: ${theme.borders.width.sm};
      background-color: ${theme.colors.content.component['close-button']};

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          right: 16px;
          margin-top: -1px;
        `,
        [SiteType.Aaf]: css`
          height: ${theme.borders.width.xs};
        `,
      })}
    `}
  `,
};

// TODO: Set correct color for sideNav-link--sale and .sideNav__leaf
const S = {
  SideNavItem: styled.div<{ $deepLevel: number; $isShown: boolean; $isNotShownForWeb: boolean }>`
    ${({ theme, $deepLevel, $isShown, $isNotShownForWeb }) => css`
      ${$deepLevel === 0 &&
      `
        transform: translateZ(0);
        border-bottom: ${`${theme.borders.width.xs} solid ${theme.colors.border.subtle}`};
        box-sizing: border-box;
        display: block;
      `};

      height: ${$isShown ? 'max-content' : '0px'};
      visibility: ${$isShown ? 'inherit' : 'hidden'};

      & .sideNav__leaf:has(.sideNav-link--marginTop) {
        margin-top: ${theme.padding.sm};
      }

      & .sideNav-link--sale,
      .sideNav-link--sale:visited,
      .sideNav-link--sale:hover,
      .sideNav-link--sale:focus {
        color: red;
      }

      & .sideNav-link--highlight {
        font-weight: 700;
      }

      & .sideNav-link--newArrivals,
      .sideNav-link--newArrivals:visited,
      .sideNav-link--newArrivals:hover,
      .sideNav-link--newArrivals:focus {
        color: ${theme.colors.content['brand-accent']};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Outlet]: css`
          & .sideNav__leaf {
            display: flex;
            align-items: center;
          }
        `,
        [SiteType.Aaf]: css`
          padding: ${$deepLevel === 0 ? `${theme.padding.xs} 0` : 0};
          margin-top: ${theme.padding['2xs']};

          & .sideNav__leaf {
            display: flex;
            align-items: center;
          }

          @media ${media(theme).greaterThan('sm')} {
            ${$isNotShownForWeb &&
            css`
              height: 0;
              visibility: hidden;
            `}
          }
        `,
      })}
    `}
  `,

  Title: styled.button<{ $deepLevel: number; $isActive: boolean }>`
    ${({ theme, $deepLevel, $isActive }) => css`
      border: 0;
      position: relative;
      cursor: pointer;
      font-family: ${theme.font.family.primary};
      font-size: ${$deepLevel === 0 ? theme.font.size[3] : '13px'};
      line-height: ${$deepLevel === 0 ? '16px' : '1.4'};
      font-weight: ${$deepLevel === 0 ? 700 : 'normal'};
      text-transform: ${$deepLevel === 0 ? 'uppercase' : 'capitalize'};
      text-align: start;
      width: 100%;

      &::before {
        ${style.CloseButton}
        transform: rotate(${$isActive ? '0' : '90deg'});
        transition: transform ${$deepLevel === 0 ? '0.5s' : '0.25s'} ease-in-out;

        @media (prefers-reduced-motion) {
          transition: none;
        }
      }

      &::after {
        ${style.CloseButton}
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          font-size: ${theme.font.size[3]};
          height: ${$deepLevel === 0 ? theme.size[16] : theme.size[12]};
          font-weight: ${$deepLevel === 0 ? '600' : '400'};
          display: block;
          padding: ${$deepLevel === 0
            ? `${theme.padding.xs} ${theme.padding['2xs']} ${theme.padding.xs} ${theme.padding.sm}`
            : `${theme.padding['2xs']} ${theme.padding['2xs']} ${theme.padding['2xs']} ${theme.padding.sm}`};
          color: ${$deepLevel === 0 ? theme.colors.content.primary : theme.colors.slate['600']};

          &::before,
          &::after {
            background-color: ${theme.colors.slate['600']};
          }

          &:hover,
          &:focus {
            color: ${$deepLevel > 0 && theme.colors.slate['600']};
          }

          @media ${media(theme).greaterThan('sm')} {
            height: ${$deepLevel === 0 ? theme.size[16] : theme.size[8]};
            ${$deepLevel !== 0 &&
            `padding:${theme.padding['3xs']} ${theme.padding['2xs']} ${theme.padding['3xs']} ${theme.padding.sm}`};
          }
        `,
        [SiteType.Outlet]: css`
          color: ${$deepLevel === 0 || $isActive
            ? theme.colors.content.primary
            : theme.colors.content.tertiary};
          padding: ${$deepLevel !== 0
            ? `${theme.padding['2xs']} ${theme.padding.xs} ${theme.padding['2xs']} ${theme.padding.sm}`
            : `${theme.size[5]} ${theme.padding.sm}`};

          &:hover,
          &:focus {
            color: ${$deepLevel > 0 && theme.colors.content.hyperlink};
          }

          @media ${media(theme).lessThan('sm')} {
            width: 299px;
            font-size: ${theme.font.size[4]};
            line-height: 20px;
            padding: ${$deepLevel === 0
              ? `${theme.size[5]} ${theme.padding.lg}`
              : `${theme.size[3]} ${theme.padding.lg}`};
          }

          & .sideNav__leaf {
            display: flex;
            align-items: center;
          }

          & .sideNav__leaf:has(.sideNav-link--marginTop) {
            margin-top: 15px;
          }
        `,
        [SiteType.Aaf]: css`
          padding: ${`${theme.padding['2xs']} ${theme.padding.xs} ${theme.padding['2xs']} ${theme.padding.sm}`};
          color: ${$deepLevel === 0
            ? theme.colors.content.primary
            : theme.colors.content.secondary};
          font-size: ${theme.font.size[3]};
        `,
      })}
    `}
  `,

  TitleText: styled.div`
    ${({ theme }) => css`
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding-right: 32px;
        `,
      })}
    `}
  `,

  SubMenuAnimation: styled.div<{ $isActive: boolean; $deepLevel: number }>`
    ${({ theme, $isActive, $deepLevel }) => css`
      box-sizing: border-box;
      overflow: hidden;
      max-height: ${$isActive ? '5000px' : 0};
      transition: max-height 0.5s ${$isActive ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)'};

      @media (prefers-reduced-motion) {
        transition: none;
      }
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          margin-top: ${$deepLevel === 0 && $isActive ? '-12px' : '0'};
          transition: max-height 0.5s ${$isActive ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)'},
            margin-top 0s cubic-bezier(0, 1, 0, 1) ${$isActive ? '0s' : '0.3s'};

          @media ${media(theme).greaterThan('sm')} {
            margin-top: ${$deepLevel === 0 && $isActive ? '-4px' : '0'};
          }
        `,
      })}
    `}
  `,
  // TODO: this is not in figma
  SubMenuWrapper: styled.div<{ $deepLevel: number; $branchChildCount: number }>`
    ${({ theme, $deepLevel, $branchChildCount }) => css`
      & > div.sideNav__branch:nth-child(${$branchChildCount}) {
        padding-bottom: ${theme.padding.xs};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding: ${theme.padding.none} ${theme.padding.none}
            ${$deepLevel === 0 ? theme.padding.xs : theme.padding['2xs']}
            ${$deepLevel === 0 ? theme.padding.none : theme.padding.sm};

          @media ${media(theme).greaterThan('sm')} {
            padding-bottom: ${$deepLevel === 0 ? theme.padding.xs : theme.padding['3xs']};
          }
        `,
        [SiteType.Aaf]: css`
          padding: ${$deepLevel !== 0 ? `0 0 ${theme.padding.xs} ${theme.padding.sm}` : 0};
        `,
      })}
    `}
  `,

  SideNavLink: styled.a<{ $isHighlighted: boolean }>`
    display: flex;
    position: relative;
    width: 100%;
  `,

  LinkTitle: styled(Typography)<{ $isHighlighted: boolean }>`
    ${({ theme, $isHighlighted }) => css`
      display: block;
      position: relative;
      width: fit-content;
      font-family: ${theme.font.family.primary};
      font-size: 13px;
      text-decoration: none;
      text-transform: capitalize;

      &:hover,
      &:focus {
        color: ${theme.colors.content.hyperlink};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding: ${theme.padding['2xs']} ${theme.padding.sm};
          line-height: 16px;
          width: 100%;
          font-size: ${theme.font.size[3]};
          font-weight: ${$isHighlighted ? '600' : '400'};
          color: ${$isHighlighted ? theme.colors.content.primary : theme.colors.slate['600']};
        `,
        [SiteType.Outlet]: css`
          line-height: 16px;
          margin: ${theme.padding['2xs']} ${theme.padding.sm};
          font-weight: ${$isHighlighted ? '600' : 'normal'};
          border-bottom: ${$isHighlighted
            ? `${theme.borders.width.sm} solid ${theme.colors.border.focused}`
            : 'none'};
          padding-bottom: ${$isHighlighted ? theme.padding['3xs'] : 0};
          color: ${$isHighlighted ? theme.colors.content.primary : theme.colors.content.tertiary};

          @media ${media(theme).lessThan('sm')} {
            margin: ${theme.padding.xs} ${theme.padding.lg};
            font-size: ${theme.font.size[4]};
            line-height: 16px;
          }
        `,
        [SiteType.Aaf]: css`
          width: 100%;
          font-size: ${theme.font.size[3]};
          line-height: 19px;
          margin: ${theme.padding['2xs']} ${theme.padding['2xs']} ${theme.padding['2xs']}
            ${theme.padding.sm};
          color: ${$isHighlighted ? theme.colors.content.primary : theme.colors.content.secondary};
        `,
      })}
    `}
  `,
};

export const SideNavItem = ({
  label,
  hideForNonLoggedInVisitors,
  hideForLoggedInVisitors,
  styleModifier,
  url,
  children,
  deepLevel,
  activeIndexArray,
  activeItemUrl,
  currentIndex,
  parentActive = true,
  updateActiveItem,
  uid,
}: SideNavItemProps): ReactElement => {
  const branchChildCount = children?.filter(child => !child?.url).length ?? 0;

  const styleObject = getStyleObject(styleModifier ?? '');
  const classNames = getClassNames(styleModifier ?? '');
  const { user } = useUserContext();

  const isActive = `${activeIndexArray?.slice(0, deepLevel + 1)}` === currentIndex;
  const loggedIn = user?.customerType === CustomerType.Registered;
  const shouldShow = !(loggedIn ? hideForLoggedInVisitors : hideForNonLoggedInVisitors);

  /**
   * fixme: is dirty hack as solution for https://g-star.atlassian.net/browse/FS-1670
   *
   * At logout action, firstly the below code will clear the items from localStorage
   * items: {countryCode}_rvp, loggedInStatus and cartEntries_{countryCode} for all regions
   *
   */
  const cleanOnLogout = () => {
    if (storageAvailable('localStorage')) {
      Object.keys(localStorage)
        .filter(
          (key: string) =>
            key.startsWith(CART_ENTRIES) ||
            key.endsWith(RECENTLY_VIEWED_PRODUCTS) ||
            key === LOGGED_IN_STATUS
        )
        .forEach((key: string) => localStorage.removeItem(key));
    }

    aa('setAuthenticatedUserToken', undefined);
  };

  return label ? (
    <S.SideNavItem
      $deepLevel={deepLevel}
      className={url ? 'sideNav__leaf' : 'sideNav__branch'}
      data-testid={activeItemUrl === url ? 'navigation-node-active' : undefined}
      $isShown={shouldShow}
      $isNotShownForWeb={uid === 'staticMyAccount_navigationNode' && !loggedIn}
    >
      {url ? (
        <S.SideNavLink
          href={url}
          onClick={() => url === '/logout' && cleanOnLogout()}
          $isHighlighted={activeItemUrl === url}
          data-title={label ?? ''}
          className={classNames}
          aria-disabled={!parentActive}
          tabIndex={!parentActive ? -1 : undefined}
        >
          <S.LinkTitle
            $isHighlighted={activeItemUrl === url}
            dangerouslySetInnerHTML={{ __html: label }}
            data-title={label ?? ''}
            style={styleObject}
          />
        </S.SideNavLink>
      ) : (
        <S.Title
          onClick={() => {
            if (activeIndexArray) {
              const currentIndexToArr = currentIndex.split(',');

              if (
                `${activeIndexArray.slice(0, currentIndexToArr.length)}` === `${currentIndexToArr}`
              ) {
                const updatedArray = activeIndexArray;

                updatedArray[currentIndexToArr.length - 1] = '-1';
                updateActiveItem([...updatedArray]);
              } else {
                updateActiveItem([...currentIndexToArr]);
              }
            }
          }}
          $isActive={isActive}
          $deepLevel={deepLevel}
          aria-expanded={isActive}
          className={classNames}
          aria-disabled={!parentActive}
          tabIndex={!parentActive ? -1 : undefined}
        >
          <S.TitleText style={styleObject} dangerouslySetInnerHTML={{ __html: label }} />
        </S.Title>
      )}
      {children && children.length > 0 && (
        <S.SubMenuAnimation $isActive={isActive} $deepLevel={deepLevel}>
          <S.SubMenuWrapper $deepLevel={deepLevel} $branchChildCount={branchChildCount}>
            {children?.map((subMenu, index) => (
              <SideNavItem
                key={index}
                {...subMenu}
                deepLevel={deepLevel + 1}
                currentIndex={`${currentIndex},${index}`}
                activeIndexArray={activeIndexArray}
                updateActiveItem={updateActiveItem}
                activeItemUrl={activeItemUrl}
                parentActive={isActive}
              />
            ))}
          </S.SubMenuWrapper>
        </S.SubMenuAnimation>
      )}
    </S.SideNavItem>
  ) : (
    <></>
  );
};
