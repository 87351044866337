import { css } from 'styled-components';
import { media } from '../core/styles';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

export const HeaderButton = css`
  ${({ theme }) => css`
    height: var(--top-nav-height);
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: ${theme.padding.none} ${theme.padding['2xs']};
    gap: ${theme.size[1]};

    ${getSiteStyles(theme.siteType, {
      [SiteType.Gstar]: css`
        padding: ${theme.padding.none};
      `,
      [SiteType.Aaf]: css`
        @media ${media(theme).greaterThan('sm')} {
          gap: ${theme.size[2]};
        }
      `,
    })}
  `}
`;
