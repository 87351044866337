// `defaultLocales` will be used to get default locale from the country code
type DefaultLocales = {
  [key: string]: string;
};

export const defaultLocales: DefaultLocales = {
  au: 'en_au',
  at: 'de_at',
  be: 'fr_be',
  bg: 'en_bg',
  ca: 'en_ca',
  hr: 'en_hr',
  cy: 'en_cy',
  cz: 'en_cz',
  dk: 'en_dk',
  ee: 'en_ee',
  fi: 'en_fi',
  fr: 'fr_fr',
  de: 'de_de',
  gr: 'en_gr',
  hk: 'en_hk',
  hu: 'en_hu',
  ie: 'en_ie',
  il: 'en_il',
  it: 'en_it',
  jp: 'ja_jp',
  kr: 'en_kr',
  lv: 'en_lv',
  lt: 'en_lt',
  lu: 'en_lu',
  mo: 'en_mo',
  nl: 'nl_nl',
  nz: 'en_nz',
  no: 'en_no',
  ph: 'en_ph',
  pl: 'en_pl',
  pt: 'en_pt',
  ro: 'en_ro',
  // ru: 'en_ru',
  sa: 'en_sa',
  sg: 'en_sg',
  sk: 'en_sk',
  si: 'en_si',
  za: 'en_za',
  es: 'es_es',
  se: 'en_se',
  ch: 'de_ch',
  tw: 'en_tw',
  th: 'en_th',
  gb: 'en_gb',
  us: 'en_us',
  ae: 'en_ae',
};
