// TODO: rename
export interface IAmplienceResponsiveImageParams {
  desktop?: string;
  tablet?: string;
  mobile?: string;
}

interface IImageObject {
  defaultHost: string;
  endpoint: string;
  name: string;
  id: string;
}

const createImageUrlFromLinkImage = (
  imageObject: IImageObject,
  params?: IAmplienceResponsiveImageParams | string
) => {
  if (imageObject) {
    const url = `https://${imageObject?.defaultHost}/i/${imageObject?.endpoint}/${encodeURI(
      imageObject.name
    )}`;

    return `${url}${params ? `?${params}` : ''}`;
  }

  return '';
};

export default createImageUrlFromLinkImage;
