import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const CloseModalIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M12.854 12.146a.501.501 0 0 1-.707.707L8 8.707l-4.146 4.146a.5.5 0 1 1-.707-.707L7.293 8 3.147 3.854a.5.5 0 1 1 .707-.708L8 7.293l4.146-4.147a.5.5 0 0 1 .708.708L8.707 8l4.147 4.146Z"
    />
  </Svg>
);

export default CloseModalIcon;
