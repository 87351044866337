import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const HamburgerIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color}>
      <path
        fillRule="nonzero"
        fillOpacity="1"
        stroke="none"
        d="M896 512a32 32 0 0 1-32 32H160a32 32 0 0 1 0-64h704a32 32 0 0 1 32 32ZM160 288h704a32 32 0 0 0 0-64H160a32 32 0 0 0 0 64Zm704 448H160a32 32 0 0 0 0 64h704a32 32 0 0 0 0-64Zm0 0"
      />
    </g>
  </Svg>
);

export default HamburgerIcon;
