import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { GlobalTopNavigation } from '../../../../amplienceTypes/schemas/imported/global-top-navigation-schema';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import { media } from '../../core/styles';
import { LocaleSelectorButton } from '../localeSelector/LocaleSelectorButton';
import { Logo } from '../logo/Logo';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

export type HeaderItemPosition = 'main-header' | 'side-header';

const S = {
  SideNavHeader: styled.div<{ $sideNavOpened: boolean }>`
    ${({ theme, $sideNavOpened }) => css`
      background: ${theme.colors.content.component.header.base};
      border-right: 1px solid ${theme.colors.border.mid};
      box-sizing: border-box;
      display: flex;
      height: var(--top-nav-height);
      left: 0;
      position: absolute;
      top: 0;
      opacity: ${$sideNavOpened ? 1 : 0};
      transform: ${$sideNavOpened
        ? 'translate3d(0,0,0)'
        : 'translate(calc(-1 * var(--side-nav-width)), 0)'};
      transition: all 0.25s ease-in-out;
      width: var(--side-nav-width);
      z-index: 112;

      ${!$sideNavOpened &&
      css`
        overflow: hidden;
      `}

      @media ${media(theme).greaterThan('sm')} {
        display: none;
      }

      @media (prefers-reduced-motion) {
        transition: none;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          border-bottom: 1px solid ${theme.colors.border.mid};
        `,
        [SiteType.Aaf]: css`
          padding-right: ${theme.size[2]};
        `,
      })}
    `}
  `,
};

export const SideNavHeader = ({
  localeSelector,
  openedModal,
  setOpenedModal,
}: GlobalTopNavigation & HeaderOpenModalProps): ReactElement => {
  const { sideNavOpened } = useNavActionContext();

  return (
    <S.SideNavHeader suppressHydrationWarning $sideNavOpened={sideNavOpened}>
      <Logo logoPosition="side-header" />
      {localeSelector?.content && (
        <LocaleSelectorButton
          buttonPosition="side-header"
          {...localeSelector?.content}
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
        />
      )}
    </S.SideNavHeader>
  );
};
