import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { useProductListContext } from '../../../utilities/context/static/ProductListContext';
import { fetchCoreProductInfo } from '../../../utilities/graphql/queries';
import { S } from './styles';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';

export const DiscontinuedProductNotification = (): ReactElement => {
  const router = useRouter();
  const { client: apolloClient } = useApolloContext();
  const { t } = useTranslation('product-list', { keyPrefix: 'globalNotification' });
  const { locale, siteType } = useAppContext();
  const { systemTexts } = useProductListContext();
  const [discontinuedMessage, setDiscontinuedMessage] = useState('');
  const message = systemTexts?.globalNotification?.message || t('message');

  useEffect(() => {
    (async () => {
      const discontinuedProductCode = router.query.discontinued as string;

      if (discontinuedProductCode) {
        const { product } = await fetchCoreProductInfo({
          siteType,
          code: discontinuedProductCode,
          locale,
          apolloClient,
        });

        if (product?.name) {
          setDiscontinuedMessage(`${product.name} ${message}`);
        }
      }
    })();
  }, [router.query, message, locale, siteType, apolloClient]);

  return discontinuedMessage ? (
    <S.MessageWrapper>
      <S.Message data-testid="global-notification-discontinued">{discontinuedMessage}</S.Message>

      <S.CloseButton
        onClick={() => setDiscontinuedMessage('')}
        icon="close"
        ordinal="transparent"
      />
    </S.MessageWrapper>
  ) : (
    <></>
  );
};
