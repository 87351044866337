import type { ReactElement } from 'react';
import Image from 'next/legacy/image';
import styled, { css } from 'styled-components';
import { media } from '../../core/styles';
import { Button } from '../../core/button/Button';
import type { GlobalAppStoreLinks } from '../../../../amplienceTypes/schemas/imported/global-app-store-links-schema';
import { useAppContext } from '~/utilities/context/static/AppContext';

const S = {
  Button: styled(Button)`
    margin-top: 8px;
  `,

  StoreLinks: styled.div`
    ${({ theme }) => css`
      display: none;
      background-color: unset;
      flex-basis: unset;
      border-top: 1px solid ${theme.colors.border.subtle};
      flex-grow: 1;
      min-width: fit-content;
      gap: ${theme.size[4]};
      justify-content: flex-end;
      height: 40px;

      @media ${media(theme).greaterThan('sm')} {
        order: 3;
        display: inline-flex;
        padding: ${theme.size[6]} ${theme.size[6]} 0 0;
      }

      @media ${media(theme).greaterThan('xl')} {
        padding: 0;
        border: unset;
      }
    `}
  `,

  Link: styled.a`
    text-decoration: none;
  `,
};

export const AppStoreLinks = ({
  appStoreLink,
  googleStoreLink,
}: GlobalAppStoreLinks): ReactElement => {
  const { cloudinaryURL2 } = useAppContext();

  return (
    <S.StoreLinks>
      {appStoreLink && (
        <S.Link href={appStoreLink} data-testid="footer-app-store-link">
          <Image
            src={`${cloudinaryURL2}image/upload/f_auto,q_51,h_80/v02/19-2/app/us/App_Store_Badge.png`}
            decoding="async"
            loading="lazy"
            alt="Download on the App Store"
            width="119"
            height="40"
          />
        </S.Link>
      )}
      {googleStoreLink && (
        <S.Link href={googleStoreLink} data-testid="footer-google-store-link">
          <Image
            src={`${cloudinaryURL2}image/upload/f_auto,q_51,h_80/v02/19-2/app/us/google_playstore.png`}
            decoding="async"
            loading="lazy"
            alt="Get it on Google Play"
            width="134"
            height="40"
          />
        </S.Link>
      )}
    </S.StoreLinks>
  );
};
