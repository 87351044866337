import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialYoutubePlainIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M17.807 5.401s-.175-1.237-.714-1.782c-.682-.715-1.448-.718-1.799-.76-2.512-.182-6.281-.182-6.281-.182h-.008s-3.769 0-6.282.182c-.35.041-1.116.045-1.799.76-.538.545-.713 1.782-.713 1.782S.03 6.854.03 8.307V9.67c0 1.453.18 2.905.18 2.905s.175 1.238.713 1.783c.683.715 1.58.692 1.98.767 1.437.138 6.105.18 6.105.18s3.773-.005 6.285-.187c.351-.042 1.117-.045 1.8-.76.538-.545.713-1.783.713-1.783s.18-1.452.18-2.905V8.307c0-1.453-.18-2.906-.18-2.906Zm-10.652 5.92V6.274l4.851 2.532-4.85 2.513Z"
    />
  </Svg>
);

export default SocialYoutubePlainIcon;
