import React, { useCallback, memo, useRef } from 'react';
import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import styled, { css, useTheme } from 'styled-components';
import isEqual from 'react-fast-compare';

import { useSearchContext } from '../../../../utilities/context/dynamic/SearchContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useMediaQueryContext } from '../../../../utilities/context/dynamic/MediaQueryContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import SearchIcon from '../../core/icons/SearchIcon';
import { colors, media } from '../../core/styles';
import type { UseComponentSizeCallback } from '../../../../utilities/dom';
import { useComponentSize, useWindowSize } from '../../../../utilities/dom';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';
import { useDataLayerContext } from '~/utilities/context/static/DataLayerContext';

const S = {
  Toggle: styled.button<{ $isSearchBoxOpen: boolean }>`
    ${({ theme, $isSearchBoxOpen }) => css`
      position: relative;
      height: var(--top-nav-height);
      padding: 18px 10px;
      display: flex;
      align-items: center;

      svg {
        width: 14px;
        opacity: ${$isSearchBoxOpen ? 0 : 1};
        transition: opacity 0.1s linear;
        transition-delay: 0.1s;

        @media (prefers-reduced-motion) {
          transition: none;
        }
      }

      label {
        color: ${colors.WHITE};
        margin-left: 10px;
        cursor: pointer;
        text-transform: lowercase;

        @media ${media(theme).lessThan('sm')} {
          display: none;
        }
      }
      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding: ${theme.padding.none};

          label {
            color: ${theme.colors.slate['600']};
            font-weight: ${theme.overrides.font?.weight.regular};
          }
        `,
      })}
    `}
  `,
};

interface ToggleSearchV2Props {
  placeholder?: string;
}

const ToggleSearchV2Component = ({ placeholder }: ToggleSearchV2Props): ReactElement => {
  const ref = useRef<HTMLButtonElement>(null);

  const { isSearchBoxOpen, setIsSearchBoxOpen, setSearchToggleSize } = useSearchContext();
  const { sideNavOpened, setSideNavOpened } = useNavActionContext();
  const { isLargeDesktop } = useMediaQueryContext();
  const { isGStar } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const theme = useTheme();

  const { t } = useTranslation('common', { keyPrefix: 'search.suggestions' });

  const { width } = useWindowSize({ debounceTime: 150 });

  // save toggle size/position so we can transition to it when opening/closing the search box
  useComponentSize(ref.current, {
    debounceTime: 150,
    callback: useCallback<UseComponentSizeCallback>(
      ([, , defaultCallback]) => defaultCallback,
      // update component size/position when window width changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [width]
    ),
    onResize: setSearchToggleSize,
  });

  const openSearchBox = useCallback(() => {
    if (!isSearchBoxOpen) {
      document.body.classList.add('no-scroll');

      if (sideNavOpened && !isLargeDesktop) {
        setSideNavOpened(false);
      }

      setIsSearchBoxOpen(true);
      pushToDataLayer({ event: 'search_opened', search_method: 'icon_click' });
    }
  }, [
    isSearchBoxOpen,
    sideNavOpened,
    isLargeDesktop,
    setIsSearchBoxOpen,
    setSideNavOpened,
    pushToDataLayer,
  ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if ((e.code === 'Space' || e.code === 'Enter') && !isSearchBoxOpen) {
        e.preventDefault();
        setIsSearchBoxOpen(true);
        pushToDataLayer({ event: 'search_opened', search_method: 'tab_navigation' });
      }
    },
    [isSearchBoxOpen, pushToDataLayer, setIsSearchBoxOpen]
  );

  return (
    <S.Toggle
      ref={ref}
      onClick={openSearchBox}
      className="search-toggle"
      data-testid="search-toggle"
      aria-label={placeholder || t('searchbar.placeholder')}
      $isSearchBoxOpen={isSearchBoxOpen}
      onKeyDown={handleKeyDown}
    >
      <SearchIcon color={isGStar ? theme.colors.content.primary : colors.WHITE} />
      <label htmlFor="search-input">{placeholder || t('searchbar.placeholder')}</label>
    </S.Toggle>
  );
};

export const ToggleSearchV2 = memo(ToggleSearchV2Component, isEqual);
